import axios from "../helpers/axios";
import { queueMachineConstants } from "./constants";

export const getAllQueueMachine = () => {
  return async (dispatch) => {
    dispatch({ type: queueMachineConstants.GET_ALL_QUEUE_MACHINE_REQUEST });

    const res = await axios.get("/api/queue-machine").catch((err) => {
      return dispatch({
        type: queueMachineConstants.GET_ALL_QUEUE_MACHINE_FAILURE,
        payload: err.response.data,
      });
    });

    if (res.status === 200) {
      dispatch({
        type: queueMachineConstants.GET_ALL_QUEUE_MACHINE_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  };
};

export const getQueueMachine = (id) => {
  return async (dispatch) => {
    dispatch({ type: queueMachineConstants.GET_QUEUE_MACHINE_REQUEST });

    const res = await axios.get(`/api/queue-machine/${id}`).catch((err) => {
      return dispatch({
        type: queueMachineConstants.GET_QUEUE_MACHINE_FAILURE,
        payload: err.response.data,
      });
    });

    if (res.status === 200) {
      dispatch({
        type: queueMachineConstants.GET_QUEUE_MACHINE_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  };
};
