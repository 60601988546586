import "./index.css";
import { Col, Row, Image } from "react-bootstrap";
import Select from "../../../components/Select";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPoliScreenSchedule, getQueueMachine } from "../../../actions";
import { getAllDoctorQueueTableScreen } from "../../../actions/policlinic.actions";
import { io } from "socket.io-client";
import { socketBase, socketPath } from "../../../urlConfig";
import { policlinicConstants } from "../../../actions/constants";
import { isAntrol } from "../../../helpers/kioskConfigs";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

const socket = io(socketBase, { path: socketPath }).connect();

export default function QueuePoliclinicDisplay() {
  const { machineId } = useParams();
  const hospitalLogo = localStorage.getItem("logo");
  const hospitalId = localStorage.getItem("hospitalId")
  const schedule_date = moment().format("YYYY-MM-DD");
  const searchParams = new URLSearchParams(window.location.search);
  // Text to Speech
  const synth = window?.speechSynthesis;
  const voice = window?.speechSynthesis?.getVoices();
  const indonesianVoice = voice.find((voice) => voice.lang.includes('id-ID'));
  const utterance = new SpeechSynthesisUtterance();
  // set to indonesian voice
  utterance.voice = indonesianVoice;
  utterance.pitch = 1;
  utterance.rate = 1;
  utterance.lang = 'id-ID'

  // Room State for socket
  const [room, setRoom] = useState(null);

  // select screen options state
  const [doctorPoliScreenOptions, setDoctorPoliScreenOptions] = useState();

  // value of selected option state
  const [doctorPoliScreenId, setDoctorPoliScreenId] = useState();

  // doctorQueueTables = doctor poli queue tables from one screen state
  const { doctorQueueTables, doctorScheduleToday } = useSelector((state) => state.policlinic);

  const dispatch = useDispatch();

  useEffect(() => {
    // initial doctor poli queue tables from one screen 
    dispatch(getQueueMachine(machineId)).then((res) => {
      //if the url has query ?screen=uuid will set the initial state of doctorPoliScreenIdfor selected policlinic screen
      const initialScreenId = searchParams.has('screen') ? searchParams.get('screen') : res?.queueItems?.[0]?._id;
      setDoctorPoliScreenId(initialScreenId);
      dispatch(getAllDoctorQueueTableScreen({ machineId, queueItemId: initialScreenId }))
       // select options
       const mapDoctorPoliScreenOptions = res?.queueItems?.map((screen) => ({
        value: screen._id,
        title: screen.name
       }));
       setDoctorPoliScreenOptions(mapDoctorPoliScreenOptions)
      });
    // initial get doctor poli with schedule for today
    dispatch(getPoliScreenSchedule({ hospital_id: hospitalId, schedule_date }))
    setRoom(machineId);
  }, [machineId]);

  useEffect(() => {
    socket.emit("join_room", room);
  }, [room]);

  useEffect(()=>{
    if(doctorPoliScreenId){
      // get doctor poli queue tables from selected screen option
      dispatch(getAllDoctorQueueTableScreen({machineId, queueItemId: doctorPoliScreenId}))
    } 
  },[doctorPoliScreenId, machineId])
  
  useEffect(() => {
    socket.on("updatePoliclinicConfigDisplay", () => {
      dispatch(getQueueMachine(machineId)).then((res) => {
        // get updated current selected screen
        dispatch(getAllDoctorQueueTableScreen({machineId, queueItemId: doctorPoliScreenId})).then((res) => {
          // if current screen displayed is removed config, display the first screen available
          if(!res){
            dispatch(getQueueMachine(machineId)).then((res) => {
              setDoctorPoliScreenId(res?.queueItems?.[0]?._id);
              dispatch(getAllDoctorQueueTableScreen({ machineId, queueItemId: res?.queueItems?.[0]?._id }))
              // select options
              const mapDoctorPoliScreenOptions = res?.queueItems?.map((screen) => ({
                value: screen._id,
                title: screen.name
              }));
              setDoctorPoliScreenOptions(mapDoctorPoliScreenOptions)
            });
          }
        });
        // get updated select options
        const mapDoctorPoliScreenOptions = res?.queueItems?.map((screen) => ({
          value: screen._id,
          title: screen.name
        }));
        setDoctorPoliScreenOptions(mapDoctorPoliScreenOptions)
      });
    });

    socket.on("createdQueuePoli", (newData) => {
     if(doctorQueueTables){
      const objectIndex = doctorQueueTables?.data.findIndex(
        (obj) => obj.uuid === newData.uuid
      );

      if (objectIndex === -1) return;

      const payloadUpdate = doctorQueueTables.data;
      const depends =  payloadUpdate[objectIndex].queueList.findIndex((data)=>data.queueNumber > newData.payload.queueNumber)
      
      if (depends === -1) {
        payloadUpdate[objectIndex].queueList.push(newData.payload);
      } else {
        payloadUpdate[objectIndex].queueList.splice(
          depends,
          0,
          newData.payload
        );
      }

      dispatch({
        type: policlinicConstants.GET_ALL_DOCTOR_QUEUE_TABLE_SCREEN_SUCCESS,
        payload: { ...doctorQueueTables, data: payloadUpdate },
      });
     }
    });

    socket.on('updatedQueuePoli', () => {
      if (doctorQueueTables) {
        dispatch(getAllDoctorQueueTableScreen({ machineId, queueItemId: doctorPoliScreenId })).then((res) => {
          // if current screen displayed is removed config, display the first screen available
          if (!res) {
            dispatch(getQueueMachine(machineId)).then((res) => {
              setDoctorPoliScreenId(res?.queueItems?.[0]?._id);
              dispatch(getAllDoctorQueueTableScreen({ machineId, queueItemId: res?.queueItems?.[0]?._id }))
              // select options
              const mapDoctorPoliScreenOptions = res?.queueItems?.map((screen) => ({
                value: screen._id,
                title: screen.name
              }));
              setDoctorPoliScreenOptions(mapDoctorPoliScreenOptions)
            });
          }
        });
      }
    })

    return () => {
      socket.off("updatePoliclinicConfigDisplay")
      socket.off("updatedQueuePoli")
      socket.off("createdQueuePoli")
    }
  }, [socket, doctorQueueTables]);

  const renderQueueTable = () => {
    return (
      <Col className="grid-container">
        {doctorQueueTables != null && doctorQueueTables?.data?.length > 0 ? (
          doctorQueueTables?.data?.filter((queueTable) => doctorScheduleToday?.data?.some((scheduleItem) => {
            const matchedSchedule = scheduleItem.poli_name === queueTable.poliName && scheduleItem.doctorHospitalId === queueTable.uuid
            if(matchedSchedule){
              queueTable.practiceTimeToday = `${scheduleItem.from_time} - ${scheduleItem.to_time}`
            }
            return matchedSchedule
          })).map((doctor, index) => (
            <div className="table-container bg-white border-bottom" key={uuidv4()}>
              <table className={`table-content ${doctorQueueTables.data.length <= 4 ? "one-row" : ""} ${doctorQueueTables.data.length > 4 && doctorQueueTables.data.length <= 8 ? "two-rows" : ""} ${doctorQueueTables.data.length > 8 && doctorQueueTables.data.length <= 12 ? "three-rows" : ""}`}>
                <thead className="table-head">
                  <tr style={{
                        padding:"8px",
                        gap:'10px',
                        opacity:'0px',
                        display:'grid',
                        maxHeight:'120px',
                        minHeight:'80px',
                        width:'100%',
                        borderRadius:'8px 8px 0px 0px',
                        background:'#011C70'}}>
                    <th className="doctor-name-column">
                      <div className="doctor-name">
                        {doctor.doctorName}
                        </div>
                      <div className="poli-name">{doctor.poliName}</div>
                      <div className="schedule-time">Jam Praktek: {doctor.practiceTimeToday ? doctor.practiceTimeToday : doctor.practiceTime}</div>
                    </th>
                  </tr>
                </thead>
                {/* 
                Explanation:
                - Queue number on consultation = isCalled true and isDoing true
                - Queue number is skipped = index is 0, isCalled true, and isDoing false
                - 1 row with 4 doctors can show up to 20 queue numbers
                - 2 row with 8 doctors can show up to 9 queue numbers
                - 3 row with 12 doctors can show up to 5 queue numbers
                - Sorting is for showing skipped queue number at the bottom of the row
                */}
                <tbody className="queue">
                  {doctor.queueList && doctor.queueList?.length > 0 ?
                    <>
                    {doctor.queueList.slice(0, doctorQueueTables.data.length <= 4 ? 20 : doctorQueueTables.data.length <= 8 ? 9 : 5).map((queueItem, index) => 
                     queueItem.isCalled == true && queueItem.isDoing == false && queueItem.isDone == false ? null :  (
                      <tr key={index} style={{position: "relative"}}>
                        <td className={`${index % 2 === 0 ? "" : "bg-secondary bg-opacity-10"} ${index === 0 ? "queue-active" : ""} ${queueItem.isCalled && queueItem.isDoing ? "queue-active queue-on-consultation" : ""}`} 
                        style={{ height: doctorQueueTables.data.length <= 4 ? "30px" : doctorQueueTables.data.length <= 8 ? "28px" :""}}> 
                        {
                          queueItem.isCalled && queueItem.isDoing &&
                          <Image src="/img/icon/icon-triple-chevron-right-green.svg" height={"25px"} style={{marginBottom:"3px"}} />
                        }
                        {
                          !(queueItem.isCalled && !queueItem.isDoing) &&
                          <span>{queueItem.queueNumber}. </span>
                        }
                        {queueItem.queuePrefixNumber}</td>
                      </tr>
                    )
                  )}
                  {
                    doctor.queueList.find((dataFind)=> dataFind.isCalled == true && dataFind.isDoing == false && dataFind.isDone == false) ? <tr>
                    <td className="text-danger">{` Terlewatkan : ${ doctor.queueList.map((queueItem) => queueItem.isCalled == true && queueItem.isDoing == false && queueItem.isDone == false ? queueItem.queuePrefixNumber : null).filter((data)=> data != null) } `}</td>
                  </tr> : null
                  }
                 
                    </> : (
                      <tr className="empty-queue">
                        <td>Belum Ada Antrian</td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>
          ))
        ) : (
          <></>
        )}
      </Col>
    )
  }

  return isAntrol ? (
    <div className="queue-bg">
      <Row className="body-container">
        <Col className={`mt-2 ${ hospitalLogo? "mt-lg-1 " : "mt-lg-4" } px-md-4 logo`}>
          <Image src={hospitalLogo ? hospitalLogo : "/img/logo/logo-klinika-dark.svg"} height={hospitalLogo ? "120px" : "50px"} />
        </Col>
        <Col className="header-title mt-5 mt-lg-0">
          LAYAR TUNGGU POLIKLINIK
        </Col>
        <Col className="col-5 col-lg-3 select mt-5 mt-lg-5 px-md-5">
          <Select
          value={doctorPoliScreenId}
          onChange={(e) => setDoctorPoliScreenId(e.target.value)}
          defaultOption
          defaultOptionTitle="Pilih Layar TV"
          data={doctorPoliScreenOptions}
          />
        </Col>
        <Col className="body-container-table mt-5 mt-lg-0">
          {renderQueueTable()}
        </Col>
      </Row>
    </div>
  ) : null
}