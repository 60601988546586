import { Row, Col } from "react-bootstrap";
import Button from "../Button";
import Modal from "../Modal";
import "./index.css";

const PopupConfirmation = (props) => {
  return (
    <Modal
      size="md"
      show={props.show}
      handleClose={props.handleClose}
      title="Konfirmasi"
      className={props.className}
    >
      <Row className="m-0">
        <Col className="mb-3 text-center">
          <span className="fw-semi-bold">{props.message}</span>
        </Col>
      </Row>
      <Row className="m-0 mb-2">
        <Col>
          <Button
            title="Ya"
            onClick={props.handleSave}
          />
        </Col>
      </Row>
      <Row className="m-0 mb-2">
        <Col>
          <Button
            title="Tidak"
            onClick={props.handleClose}
            className="button-cancel-modal"
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default PopupConfirmation;