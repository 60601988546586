import { queueItemConstants } from "../actions/constants";

const initState = {
  allQueueItem: null,
  queueItem: null,
  allLoading: false,
  loading: false,
  allError: null,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case queueItemConstants.GET_ALL_QUEUE_ITEM_REQUEST:
      state = {
        ...state,
        allLoading: true,
      };
      break;
    case queueItemConstants.GET_ALL_QUEUE_ITEM_SUCCESS:
      state = {
        ...state,
        allQueueItem: action.payload,
        allLoading: false,
      };
      break;
    case queueItemConstants.GET_ALL_QUEUE_ITEM_FAILURE:
      state = {
        ...state,
        allQueueItem: null,
        allError: action.payload,
        allLoading: false,
      };
      break;
    case queueItemConstants.GET_QUEUE_ITEM_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case queueItemConstants.GET_QUEUE_ITEM_SUCCESS:
      state = {
        ...state,
        queueItem: action.payload,
        loading: false,
      };
      break;
    case queueItemConstants.GET_QUEUE_ITEM_FAILURE:
      state = {
        ...state,
        queueItem: null,
        error: action.payload,
        loading: false,
      };
      break;
    case queueItemConstants.CLEAR_GET_QUEUE_ITEM: 
      state = {
        ...state,
        queueItem : null,
      }
    default: 
      break;
  }
  return state;
};
