import axios from "../helpers/axios";
import { ERROR_RESPONSE, counterConstants } from "./constants";

export const getAllCounter = () => {
  return async (dispatch) => {
    dispatch({ type: counterConstants.GET_ALL_COUNTER_REQUEST });

    const res = await axios.get("/api/counter").catch((err) => {
      return dispatch({
        type: counterConstants.GET_ALL_COUNTER_FAILURE,
        payload: err.response.data,
      });
    });

    if (res.status === 200) {
      dispatch({
        type: counterConstants.GET_ALL_COUNTER_SUCCESS,
        payload: res.data || [],
      });

      return res.data || [];
    }
  };
};

export const getCounter = (id) => {
  return async (dispatch) => {
    dispatch({ type: counterConstants.GET_ALL_COUNTER_REQUEST });

    const res = await axios.get(`/api/counter/${id}`).catch((err) => {
      return dispatch({
        type: counterConstants.GET_ALL_COUNTER_FAILURE,
        payload: err.response.data,
      });
    });

    if (res.status === 200) {
      dispatch({
        type: counterConstants.GET_ALL_COUNTER_SUCCESS,
        payload: res.data || [],
      });

      return res.data || [];
    }
  };
};

export const createCounterAdmissionConfig = (body) => {
  return async (dispatch) => {
    try {
      dispatch({ type: counterConstants.CREATE_COUNTER_ADMISSION_CONFIG_REQUEST });

      const res = await axios.post("/api/counter/admission-config", body).catch((err) => {
        dispatch({
          type: counterConstants.CREATE_COUNTER_ADMISSION_CONFIG_FAILURE,
          payload: err.response?.data || ERROR_RESPONSE,
        });
        throw err
      });

      if (res.status === 200) {
        dispatch({
          type: counterConstants.CREATE_COUNTER_ADMISSION_CONFIG_SUCCESS,
          payload: res.data || [],
        });

        return res.data || [];
      }
    } catch (error) {
      throw error
    }
  };
};

export const updateCounterAdmissionConfig = (id, body) => {
  return async (dispatch) => {
    try {
      dispatch({ type: counterConstants.UPDATE_COUNTER_ADMISSION_CONFIG_REQUEST });

      const res = await axios.put(`/api/counter/admission-config/${id}`, body).catch((err) => {
        dispatch({
          type: counterConstants.UPDATE_COUNTER_ADMISSION_CONFIG_FAILURE,
          payload: err.response?.data || ERROR_RESPONSE,
        });
        throw err
      });

      if (res.status === 200) {
        dispatch({
          type: counterConstants.UPDATE_COUNTER_ADMISSION_CONFIG_SUCCESS,
          payload: res.data || [],
        });
        return res.data || [];
      }
    } catch (error) {
      throw error
    }
  };
};

export const deleteCounterAdmissionConfig = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: counterConstants.DELETE_COUNTER_ADMISSION_CONFIG_REQUEST });

      const res = await axios.delete(`/api/counter/admission-config/${id}`).catch((err) => {
        return dispatch({
          type: counterConstants.DELETE_COUNTER_ADMISSION_CONFIG_FAILURE,
          payload: err.response?.data || ERROR_RESPONSE,
        });
      });

      if (res.status === 200) {
        dispatch({
          type: counterConstants.DELETE_COUNTER_ADMISSION_CONFIG_SUCCESS,
          payload: res.data || [],
        });

        return res.data || [];
      }
    } catch (error) {
      throw error
    }
  };
};