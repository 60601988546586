import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { createQueueProcess, getQueueMachine } from "../../actions";
import Button from "../../components/Button";
import { encode as base64_encode } from "base-64";
import "./index.css";
import PatientLablePreview from "./Preview";
import { useReactToPrint } from 'react-to-print';
import { PREFIX_M_JKN } from "../../varConstant";
import useCheckConfig from "../../hooks/useCheckConfig";
import Topbar from "../../components/Topbar/index.";
import BackButton from "../../components/BackButton";

const PatientDisplay = () => {
  const { machineId } = useParams();
  let componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      console.log("BEFORE");
    },
  });

  const queueProcess = useSelector((state) => state.queueProcess);
  const queueMachine = useSelector((state) => state.queueMachine);
  const [dataEncoded, setDataEncoded] = useState();
  const dispatch = useDispatch();

  const checkConfigPatientDisplay = useCheckConfig('enablePatientDisplay');
  const checkConfigPatientDisplayV2 = useCheckConfig('enablePatientDisplayV2');

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getQueueMachine(machineId)).catch((err) => {
      console.log(err);
    });
  }, [dispatch, machineId]);

  useEffect(() => {
    if (queueProcess.createQueueProcess) {
      const encodedData = base64_encode(
        JSON.stringify(queueProcess.createQueueProcess)
      );
      setDataEncoded(encodedData);
      // window.open(`/patient-display/preview/${encodedData}`, "_blank");

    }
  }, [queueProcess.createQueueProcess]);

  const handleCreate = async (itemId) => {
    let body = {
      queueMachine: machineId,
      queueItem: itemId,
    };
    if(checkConfigPatientDisplay){
      navigate(`/patient-display/${machineId}/${itemId}`, {
        state: queueMachine?.queueMachine?.queueItem
      })
    } else {
      return new Promise((resolve, reject) => {
        dispatch(createQueueProcess(body));
        setTimeout(() => {
          resolve()
        }, 1000);
      });
    }

   
  };

  const handleCreateAndPrint = (itemId) => {
    handleCreate(itemId).then( () => {
        handlePrint()
      if (checkConfigPatientDisplayV2) {
        setTimeout(() => {
          navigate(`/patient-display/admission/${machineId}`)
        }, [1000])
      }
    }).catch((error) => {
      console.error("Erorr dispatching: ", error);
    })
  }

  const handleClassNameButton = useMemo(() => {
    return "button-patient-control-h-80"
    // return (queueMachine.queueMachine?.queueItems?.length < 4 ? "button-control-h-default" : (queueMachine.queueMachine?.queueItems?.length < 5 ? "button-control-h-100" : "button-control-h-80"))
  }, [queueMachine.queueMachine?.queueItems])

  const handleBack = () => {
    navigate(-1);
  }

  return (
    <div className="patient-bg font-family-patient-display">
      <div className="patient-header">
        <Topbar />
      </div>
      <div className="patient-container">
        <Row className="m-0 mb-4 d-flex justify-content-center" >
          <Col md={11}>
            <Row className="justify-content-center patient-display-title">
              Silahkan pilih loket pendaftaran yang ingin Anda tuju
            </Row>
          </Col>
        </Row>
        {queueMachine.queueMachine?.queueItems?.length &&
          queueMachine.queueMachine.queueItems.filter(el=>el.prefix !== PREFIX_M_JKN).map((data, index) => (
            <Row className="m-0 mb-4 d-flex justify-content-center" key={index}>
              <Col md={9} className="text-center">
                <Button
                  className={handleClassNameButton}
                  title={data.name}
                  onClick={() => handleCreateAndPrint(data._id)}
                  classNameTitle={checkConfigPatientDisplayV2 ? "button-description-title" : ""}
                />
              </Col>
            </Row>
          ))}
      </div>

      {
        checkConfigPatientDisplayV2 && <div>
          <Row className="m-0 d-flex" >
            <Col sm={3} xl={3} className="text-center">
              <BackButton handleBack={handleBack} />
            </Col>
          </Row>
        </div>
      }


      <div>
      <div style={{ display: "none" }}>
        <PatientLablePreview ref={componentRef} data={dataEncoded} />
      </div>
        {/* <button onClick={() => handlePrint()}>Print this out!</button> */}
      </div>
    </div>
  );
};

export default PatientDisplay;
