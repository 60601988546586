import { useEffect, useState } from "react";
import { retrieveLocalStorageItem } from "../helpers/localStorage";

export default function useCheckConfig(type) {
	const [isEnable, setIsEnable] = useState(false);
	const kioskRole = retrieveLocalStorageItem("configs");
    
	useEffect(() => {
		if (!kioskRole) setIsEnable(false);
		const config = kioskRole?.[`${type}`] || false;
		setIsEnable(config);
	}, [kioskRole]);

	return isEnable;
}