import authReducers from "./auth.reducers";
import queueMachineReducers from "./queueMachine.reducers";
import counterReducers from "./counter.reducers";
import queueProcessReducers from "./queueProcess.reducers";
import queueItemReducers from "./queueItem.reducers";
import bookAppointmentReducers from "./bookAppointment.reducers";
import { combineReducers } from "redux";
import policlinicReducers from "./policlinic.reducers";

const rootReducer = combineReducers({
  auth: authReducers,
  queueMachine: queueMachineReducers,
  counter: counterReducers,
  queueProcess: queueProcessReducers,
  queueItem : queueItemReducers, 
  bookAppointment: bookAppointmentReducers,
  policlinic: policlinicReducers
});

export default rootReducer;
