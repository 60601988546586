import { authConstants } from "../actions/constants";

const initState = {
  accessToken: null,
  refreshToken: null,
  authenticate: false,
  authenticating: false,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      state = {
        ...state,
        authenticate: false,
        authenticating: true,
      };
      break;
    case authConstants.LOGIN_SUCCESS:
      state = {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        authenticate: true,
        authenticating: false,
      };
      break;
    case authConstants.LOGOUT_SUCCESS:
      state = {
        ...initState,
      };
      break;
    case authConstants.LOGIN_FAILURE:
      state = {
        ...initState,
      };
      break;
  }
  return state;
};
