import { queueMachineConstants } from "../actions/constants";

const initState = {
  allQueueMachine: null,
  queueMachine: null,
  allLoading: false,
  loading: false,
  allError: null,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case queueMachineConstants.GET_ALL_QUEUE_MACHINE_REQUEST:
      state = {
        ...state,
        allLoading: true,
      };
      break;
    case queueMachineConstants.GET_ALL_QUEUE_MACHINE_SUCCESS:
      state = {
        ...state,
        allQueueMachine: action.payload,
        allLoading: false,
      };
      break;
    case queueMachineConstants.GET_ALL_QUEUE_MACHINE_FAILURE:
      state = {
        ...state,
        allQueueMachine: null,
        allError: action.payload,
        allLoading: false,
      };
      break;
    case queueMachineConstants.GET_QUEUE_MACHINE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case queueMachineConstants.GET_QUEUE_MACHINE_SUCCESS:
      state = {
        ...state,
        queueMachine: action.payload,
        loading: false,
      };
      break;
    case queueMachineConstants.GET_QUEUE_MACHINE_FAILURE:
      state = {
        ...state,
        queueMachine: null,
        error: action.payload,
        loading: false,
      };
      break;
    default: 
      break;
  }
  return state;
};
