import "./index.css";
import { Button as Btn } from "react-bootstrap";

const Button = (props) => {
  return (
    <Btn
      className={props.className ? props.className : "button-control"}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.title && <div className={props?.classNameTitle ? props?.classNameTitle : null}>
        {props.title}
      </div>}
      {props.description && <div className="button-description-admission">
        {props.description}
      </div>}
    </Btn>
  );
};

export default Button;
