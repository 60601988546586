export const authConstants = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
};

export const queueMachineConstants = {
  GET_QUEUE_MACHINE_REQUEST: "GET_QUEUE_MACHINE_REQUEST",
  GET_QUEUE_MACHINE_FAILURE: "GET_QUEUE_MACHINE_FAILURE",
  GET_QUEUE_MACHINE_SUCCESS: "GET_QUEUE_MACHINE_SUCCESS",
  GET_ALL_QUEUE_MACHINE_REQUEST: "GET_ALL_QUEUE_MACHINE_REQUEST",
  GET_ALL_QUEUE_MACHINE_FAILURE: "GET_ALL_QUEUE_MACHINE_FAILURE",
  GET_ALL_QUEUE_MACHINE_SUCCESS: "GET_ALL_QUEUE_MACHINE_SUCCESS",
};

export const counterConstants = {
  GET_COUNTER_REQUEST: "GET_COUNTER_REQUEST",
  GET_COUNTER_FAILURE: "GET_COUNTER_FAILURE",
  GET_COUNTER_SUCCESS: "GET_COUNTER_SUCCESS",
  GET_ALL_COUNTER_REQUEST: "GET_ALL_COUNTER_REQUEST",
  GET_ALL_COUNTER_FAILURE: "GET_ALL_COUNTER_FAILURE",
  GET_ALL_COUNTER_SUCCESS: "GET_ALL_COUNTER_SUCCESS",
  CREATE_COUNTER_ADMISSION_CONFIG_REQUEST: "CREATE_COUNTER_ADMISSION_CONFIG_REQUEST",
  CREATE_COUNTER_ADMISSION_CONFIG_FAILURE: "CREATE_COUNTER_ADMISSION_CONFIG_FAILURE",
  CREATE_COUNTER_ADMISSION_CONFIG_SUCCESS: "CREATE_COUNTER_ADMISSION_CONFIG_SUCCESS",
  UPDATE_COUNTER_ADMISSION_CONFIG_REQUEST: "UPDATE_COUNTER_ADMISSION_CONFIG_REQUEST",
  UPDATE_COUNTER_ADMISSION_CONFIG_FAILURE: "UPDATE_COUNTER_ADMISSION_CONFIG_FAILURE",
  UPDATE_COUNTER_ADMISSION_CONFIG_SUCCESS: "UPDATE_COUNTER_ADMISSION_CONFIG_SUCCESS",
  DELETE_COUNTER_ADMISSION_CONFIG_REQUEST: "DELETE_COUNTER_ADMISSION_CONFIG_REQUEST",
  DELETE_COUNTER_ADMISSION_CONFIG_FAILURE: "DELETE_COUNTER_ADMISSION_CONFIG_FAILURE",
  DELETE_COUNTER_ADMISSION_CONFIG_SUCCESS: "DELETE_COUNTER_ADMISSION_CONFIG_SUCCESS",
};

export const queueProcessConstants = {
  GET_QUEUE_PROCESS_REQUEST: "GET_QUEUE_PROCESS_REQUEST",
  GET_QUEUE_PROCESS_FAILURE: "GET_QUEUE_PROCESS_FAILURE",
  GET_QUEUE_PROCESS_SUCCESS: "GET_QUEUE_PROCESS_SUCCESS",
  GET_ALL_QUEUE_PROCESS_REQUEST: "GET_ALL_QUEUE_PROCESS_REQUEST",
  GET_ALL_QUEUE_PROCESS_FAILURE: "GET_ALL_QUEUE_PROCESS_FAILURE",
  GET_ALL_QUEUE_PROCESS_SUCCESS: "GET_ALL_QUEUE_PROCESS_SUCCESS",
  NEXT_CALL_QUEUE_PROCESS_REQUEST: "NEXT_CALL_QUEUE_PROCESS_REQUEST",
  NEXT_CALL_QUEUE_PROCESS_FAILURE: "NEXT_CALL_QUEUE_PROCESS_FAILURE",
  NEXT_CALL_QUEUE_PROCESS_SUCCESS: "NEXT_CALL_QUEUE_PROCESS_SUCCESS",
  CREATE_QUEUE_PROCESS_REQUEST: "CREATE_QUEUE_PROCESS_REQUEST",
  CREATE_QUEUE_PROCESS_FAILURE: "CREATE_QUEUE_PROCESS_FAILURE",
  CREATE_QUEUE_PROCESS_SUCCESS: "CREATE_QUEUE_PROCESS_SUCCESS",
  GET_ALL_SKIPPED_QUEUE_PROCESS_REQUEST: "GET_ALL_SKIPPED_QUEUE_PROCESS_REQUEST",
  GET_ALL_SKIPPED_QUEUE_PROCESS_FAILURE: "GET_ALL_SKIPPED_QUEUE_PROCESS_FAILURE",
  GET_ALL_SKIPPED_QUEUE_PROCESS_SUCCESS: "GET_ALL_SKIPPED_QUEUE_PROCESS_SUCCESS",
  UPDATE_SKIPPED_QUEUE_PROCESS_REQUEST: "UPDATE_SKIPPED_QUEUE_PROCESS_REQUEST",
  UPDATE_SKIPPED_QUEUE_PROCESS_FAILURE: "UPDATE_SKIPPED_QUEUE_PROCESS_FAILURE",
  UPDATE_SKIPPED_QUEUE_PROCESS_SUCCESS: "UPDATE_SKIPPED_QUEUE_PROCESS_SUCCESS",
  GET_DASHBOARD_REQUEST: "GET_DASHBOARD_REQUEST",
  GET_DASHBOARD_FAILURE: "GET_DASHBOARD_FAILURE",
  GET_DASHBOARD_SUCCESS: "GET_DASHBOARD_SUCCESS",
  CREATE_QUEUE_PROCESS_REQUEST_V2 : "CREATE_QUEUE_PROCESS_REQUEST_V2",
  CREATE_QUEUE_PROCESS_FAILURE_V2: "CREATE_QUEUE_PROCESS_FAILURE_V2",
  CREATE_QUEUE_PROCESS_SUCCESS_V2: "CREATE_QUEUE_PROCESS_SUCCESS_V2",
};


export const queueItemConstants = {
  GET_QUEUE_ITEM_REQUEST: "GET_QUEUE_ITEM_REQUEST",
  GET_QUEUE_ITEM_FAILURE: "GET_QUEUE_ITEM_FAILURE",
  GET_QUEUE_ITEM_SUCCESS: "GET_QUEUE_ITEM_SUCCESS",
  GET_ALL_QUEUE_ITEM_REQUEST: "GET_ALL_QUEUE_ITEM_REQUEST",
  GET_ALL_QUEUE_ITEM_FAILURE: "GET_ALL_QUEUE_ITEM_FAILURE",
  GET_ALL_QUEUE_ITEM_SUCCESS: "GET_ALL_QUEUE_ITEM_SUCCESS",
  CLEAR_GET_QUEUE_ITEM : "CLEAR_GET_QUEUE_ITEM",
};


export const masterDataConstants = {
  GET_DOCTOR_HOSPITAL_REQUEST : "GET_DOCTOR_HOSPITAL_REQUEST",
  GET_DOCTOR_HOSPITAL_FAILURE : "GET_DOCTOR_HOSPITAL_FAILURE",
  GET_DOCTOR_HOSPITAL_SUCCESS : "GET_DOCTOR_HOSPITAL_SUCCESS",
  GET_DOCTOR_HOSPITAL_CLEAR : "GET_DOCTOR_HOSPITAL_CLEAR",
  GET_POLI_REQUEST : "GET_POLI_REQUEST",
  GET_POLI_FAILURE : "GET_POLI_FAILURE",
  GET_POLI_SUCCESS : "GET_POLI_SUCCESS",
  GET_SCHEDULE_REQUEST : "GET_SCHEDULE_REQUEST",
  GET_SCHEDULE_FAILURE : "GET_SCHEDULE_FAILURE",
  GET_SCHEDULE_SUCCESS : "GET_SCHEDULE_SUCCESS",
  GET_SCHEDULE_CLEAR: "GET_SCHEDULE_CLEAR"
}

export const allQueueMachine = {
  ADMISSION_SLUG: 'antrian-rawat-jalan',
  POLICLINIC_SLUG: 'antrian-poliklinik',
}

export const policlinicConstants = {
  GET_POLICLINIC_SCREEN_REQUEST: "GET_POLICLINIC_SCREEN_REQUEST",
  GET_POLICLINIC_SCREEN_FAILURE: "GET_POLICLINIC_SCREEN_FAILURE",
  GET_POLICLINIC_SCREEN_SUCCESS: "GET_POLICLINIC_SCREEN_SUCCESS",
  CREATE_POLICLINIC_SCREEN_REQUEST: "CREATE_POLICLINIC_SCREEN_REQUEST",
  CREATE_POLICLINIC_SCREEN_FAILURE: "CREATE_POLICLINIC_SCREEN_FAILURE",
  CREATE_POLICLINIC_SCREEN_SUCCESS: "CREATE_POLICLINIC_SCREEN_SUCCESS",
  UPDATE_POLICLINIC_SCREEN_REQUEST: "UPDATE_POLICLINIC_SCREEN_REQUEST",
  UPDATE_POLICLINIC_SCREEN_FAILURE: "UPDATE_POLICLINIC_SCREEN_FAILURE",
  UPDATE_POLICLINIC_SCREEN_SUCCESS: "UPDATE_POLICLINIC_SCREEN_SUCCESS",
  DELETE_POLICLINIC_SCREEN_REQUEST: "DELETE_POLICLINIC_SCREEN_REQUEST",
  DELETE_POLICLINIC_SCREEN_FAILURE: "DELETE_POLICLINIC_SCREEN_FAILURE",
  DELETE_POLICLINIC_SCREEN_SUCCESS: "DELETE_POLICLINIC_SCREEN_SUCCESS",
  GET_ALL_DOCTOR_POLI_LIST_REQUEST: "GET_ALL_DOCTOR_POLI_LIST_REQUEST",
  GET_ALL_DOCTOR_POLI_LIST_SUCCESS: "GET_ALL_DOCTOR_POLI_LIST_SUCCESS",
  GET_ALL_DOCTOR_POLI_LIST_FAILURE: "GET_ALL_DOCTOR_POLI_LIST_FAILURE",
  GET_ALL_POLI_LIST_REQUEST: "GET_ALL_POLI_LIST_REQUEST",
  GET_ALL_POLI_LIST_SUCCESS: "GET_ALL_POLI_LIST_SUCCESS",
  GET_ALL_POLI_LIST_FAILURE: "GET_ALL_POLI_LIST_FAILURE",
  GET_ALL_DOCTOR_QUEUE_TABLE_SCREEN_REQUEST: "GET_ALL_DOCTOR_QUEUE_TABLE_SCREEN_REQUEST",
  GET_ALL_DOCTOR_QUEUE_TABLE_SCREEN_SUCCESS: "GET_ALL_DOCTOR_QUEUE_TABLE_SCREEN_SUCCESS",
  GET_ALL_DOCTOR_QUEUE_TABLE_SCREEN_FAILURE: "GET_ALL_DOCTOR_QUEUE_TABLE_SCREEN_FAILURE",
  GET_POLICLINIC_SCREEN_DOCTOR_SCHEDULE_REQUEST: "GET_POLICLINIC_SCREEN_DOCTOR_SCHEDULE_REQUEST",
  GET_POLICLINIC_SCREEN_DOCTOR_SCHEDULE_SUCCESS: "GET_POLICLINIC_SCREEN_DOCTOR_SCHEDULE_SUCCESS",
  GET_POLICLINIC_SCREEN_DOCTOR_SCHEDULE_FAILURE: "GET_POLICLINIC_SCREEN_DOCTOR_SCHEDULE_FAILURE"
}

export const ERROR_RESPONSE = {
  errorCode: 500,
  errorMessage: "Sedang ada kendala, mohon coba beberapa saat lagi"
  }

  export const maxLimit={
    tvDisplayMissed : 12,
    tvCounter : 5
  }