import axiosInstance from "../helpers/axios";
import { ERROR_RESPONSE, policlinicConstants } from "./constants";

export const getPoliclinicScreen = (id) => {
  return async (dispatch) => {
    dispatch({ type: policlinicConstants.GET_POLICLINIC_SCREEN_REQUEST });

    const res = await axiosInstance.get(`/api/queue-item/${id}`).catch((err) => {
      return dispatch({
        type: policlinicConstants.GET_POLICLINIC_SCREEN_FAILURE,
        payload: err.response?.data || ERROR_RESPONSE
      });
    });

    if (res.status === 200) {
      dispatch({
        type: policlinicConstants.GET_POLICLINIC_SCREEN_SUCCESS,
        payload: res.data || [],
      });

      return res.data || [];
    }
  };
};

export const createPoliclinicScreen = (body) => {
  return async (dispatch) => {
    dispatch({ type: policlinicConstants.CREATE_POLICLINIC_SCREEN_REQUEST });

    const res = await axiosInstance.post(`/api/queue-item/poli`, body).catch((err) => {
      return dispatch({
        type: policlinicConstants.CREATE_POLICLINIC_SCREEN_FAILURE,
        payload: err.response?.data || ERROR_RESPONSE
      });
    });

    if (res.status === 200) {
      dispatch({
        type: policlinicConstants.CREATE_POLICLINIC_SCREEN_SUCCESS,
        payload: res.data || [],
      });

      return res.data || [];
    }
  };
};

export const updatePoliclinicScreen = (id, body) => {
  return async (dispatch) => {
    dispatch({ type: policlinicConstants.UPDATE_POLICLINIC_SCREEN_REQUEST });

    const res = await axiosInstance.put(`/api/queue-item/poli/${id}`, body).catch((err) => {
      return dispatch({
        type: policlinicConstants.UPDATE_POLICLINIC_SCREEN_FAILURE,
        payload: err.response?.data || ERROR_RESPONSE
      });
    });

    if (res.status === 200) {
      dispatch({
        type: policlinicConstants.UPDATE_POLICLINIC_SCREEN_SUCCESS,
        payload: res.data.data || [],
      });

      return res.data || [];
    }
  };
};

export const deletePoliclinicScreen = (id) => {
  return async (dispatch) => {
    dispatch({ type: policlinicConstants.DELETE_POLICLINIC_SCREEN_REQUEST });

    const res = await axiosInstance.delete(`/api/queue-item/poli/${id}`).catch((err) => {
      return dispatch({
        type: policlinicConstants.DELETE_POLICLINIC_SCREEN_FAILURE,
        payload: err.response?.data || ERROR_RESPONSE
      });
    });

    if (res.status === 200) {
      dispatch({
        type: policlinicConstants.DELETE_POLICLINIC_SCREEN_SUCCESS,
        payload: res.data || [],
      });

      return res.data || [];
    }
  };
};

export const getAllDoctorQueueTableScreen = (query) => {
  const { machineId, queueItemId } = query;
  return async (dispatch) => {
    dispatch({ type: policlinicConstants.GET_ALL_DOCTOR_QUEUE_TABLE_SCREEN_REQUEST });

    const res = await axiosInstance.get(`/api/queue-process/poli?machineId=${machineId}&queueItemId=${queueItemId}`).catch((err) => {
      return dispatch({
        type: policlinicConstants.GET_ALL_DOCTOR_QUEUE_TABLE_SCREEN_FAILURE,
        payload: err.response?.data || ERROR_RESPONSE
      });
    });

    if (res.status === 200) {
      dispatch({
        type: policlinicConstants.GET_ALL_DOCTOR_QUEUE_TABLE_SCREEN_SUCCESS,
        payload: res.data || []
      });

      return res.data || [];
    }
  }
}