import React, { useEffect } from 'react';
import './index.css'; // Let's assume you have this CSS file for custom styles
import { HiArrowSmLeft, HiArrowSmRight } from 'react-icons/hi';

export default function Pagination({ style, props, setProps, onChange }) {
  let { page, limit, totalData } = props;
  let lastPage = Math.ceil(totalData / limit);

  const isLastPage = () => {
    return page === lastPage;
  };

  useEffect(() => {
    onChange(page, limit);  
  }, [page, limit, totalData]);

  return (
    <>
      <div className="row">
        <div className="col-md-12" style={style ? style : { marginTop: '10px' }}>
          <nav aria-label="..." className="pull-right">
            <ul
              className="pagination"
              style={{
                margin: 'auto',
                justifyContent: 'flex-end',
              }}
            >
              <li className={`page-item ${page === 1 && 'disabled'}`}>
                <div
                  className="page-link"
                  onClick={() => {
                    if (page > 1) {
                      setProps((prev) => ({
                        ...prev,
                        page: prev.page - 1,
                      }));
                    }
                  }}
                >
                  <HiArrowSmLeft className="cl-green cursor-pointer" style={{ fontSize: '20px' }} />   
                </div>
              </li>

              {[...Array(lastPage)].map((e, i) => {
                i += 1;
                return (
                  <React.Fragment key={i}>
                    {page > 2 && i === 2 ? (
                      <li className="page-item disabled">
                        <span className="page-link">...</span>
                      </li>
                    ) : null}

                    {i === page ? (
                      <li className="page-item active">
                        <div className="page-link">
                          {i}
                        </div>
                      </li>
                    ) : i === page + 1 ||
                      i === page + 2 ||
                      i === page - 1 ||
                      i === page - 2 ||
                      i === lastPage ||
                      i === 1 ? (
                      <li className="page-item">
                        <div
                          className="page-link"
                          onClick={() => {
                            setProps((prev) => ({
                              ...prev,
                              page: i,
                            }));
                          }}
                        >
                          {i}
                        </div>
                      </li>
                    ) : null}

                    {page < lastPage - 2 && i === lastPage - 1 ? (
                      <li className="page-item disabled">
                        <span className="page-link">...</span>
                      </li>
                    ) : null}
                  </React.Fragment>
                );
              })}

              <li className={`page-item ${isLastPage() && 'disabled'}`}>
                <div
                  className="page-link"
                  onClick={() => {
                    if (!isLastPage()) {
                      setProps((prev) => ({
                        ...prev,
                        page: prev.page + 1,
                      }));
                    }
                  }}
                >
                  <HiArrowSmRight className="cl-green cursor-pointer" style={{ fontSize: '20px' }} />   
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}
