import { HiArrowSmLeft } from "react-icons/hi";
import Button from "../Button";

const BackButton = ({
    handleBack,
}) => {

    const icon = () => {
        return <HiArrowSmLeft className="cl-green cursor-pointer" style={{ fontSize: '40px' }} />       
    }
    

    const title = () => {
        return (
            <div className="flex items-center cursor-pointer d-flex" style={{justifyContent:'space-evenly'}}>
             {icon()}
              <span className="fw-bold fs-30 cursor-pointer ml-04 title-back-button" >Kembali</span>
            </div>
          );
        }
    return (
        <>  
            <Button
                className={("button-back")}
                title={title()}
                onClick={handleBack}

            />
        </>
    )
}

export default BackButton;