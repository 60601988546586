import React, { useEffect, useState } from "react";
import moment from "moment";
import { decode as base64_decode } from "base-64";

const PatientPreviewPoliclinic = React.forwardRef((props, ref) => {
  const [data, setData] = useState();
  useEffect(() => {
    if (props.data) {
      const decodedData = base64_decode(props.data)
        ? JSON.parse(base64_decode(props.data))
        : "";
      setData(decodedData);
    }
  }, [props.data]);

  const pixelTo = {
    mm: (pixel) => parseFloat(pixel) * 0.264583 + "mm",
    cm: (pixel) => parseFloat(pixel) * 0.0264583 + "cm",
    pt: (pixel) => parseFloat(pixel) * 0.75 + "pt",
  };

  return (
    <div ref={ref}>
      <table className="kiosk-font-face">
        <thead>
          <tr>
            <th style={{ fontSize: pixelTo.pt("16px"), letterSpacing: pixelTo.pt("1px"), fontWeight: "900", textAlign: "center" }}>
              {data?.hospital?.name ? data.hospital.name : "-"}
              {/* Siloam Clinic Galaxy */}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ fontSize: pixelTo.pt("10px"), letterSpacing: pixelTo.pt("1px"), fontWeight: "700", textAlign: "center" }}>
              {data?.queueDate ? moment(data.queueDate).format("DD-MM-YYYY") : "-"} &nbsp;&nbsp; {data?.queueTime ? data.queueTime : "-"}
              {/* 26-12-2023    12:22:04 */}
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: pixelTo.pt("8px"), letterSpacing: pixelTo.pt("1px"), fontWeight: "900", textAlign: "center" }}>
              {"Antrian Dokter Spesialis"}
              {/* Ambil Antrian */}
            </td>
          </tr>
          {
            <tr>
              <td style={{ fontSize: "10px", letterSpacing: "1px", fontWeight: "500", textAlign: "center" }}>
                {data?.doctorName ? data.doctorName : "-"}
              </td>
            </tr>
          }
          <tr>
            <td style={{ fontSize: pixelTo.pt("8px"), letterSpacing: pixelTo.pt("1px"), fontWeight: "700", textAlign: "center" }}>
              Nomor Antrian
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: pixelTo.pt("24px"), letterSpacing: pixelTo.pt("1px"), fontWeight: "900", textAlign: "center" }}>
              {data?.queuePrefixNumber ? data.queuePrefixNumber : "-"}
              {/* K-15 */}
            </td>
          </tr>

          {
            <tr>
              <td style={{ fontSize: "10px", letterSpacing: "1px", fontWeight: "500", textAlign: "center" }}>
                Estimasi dilayani: {data?.scheduleDoctor ? data.scheduleDoctor : "-"}
              </td>
            </tr>
          }
          <tr>
            <td style={{ fontSize: pixelTo.pt("8px"), marginTop: pixelTo.pt("4px"), letterSpacing: pixelTo.pt("1px"), fontWeight: "700", textAlign: "center", marginBottom: '12px' }}>
              {
                '*Harap hadir 90 menit sebelum jas estimasi dilayani'
              }
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
});

export default PatientPreviewPoliclinic;
