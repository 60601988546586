import { Modal as Mdl } from "react-bootstrap";

const Modal = (props) => {
  return (
    <Mdl size={props.size} show={props.show} onHide={props.handleClose} className={props.className}>
      <Mdl.Header closeButton>
        <Mdl.Title>{props.title}</Mdl.Title>
      </Mdl.Header>

      <Mdl.Body>{props.children}</Mdl.Body>
    </Mdl>
  );
};

export default Modal;
