import useCheckConfig from "../../hooks/useCheckConfig";
import QueueAdmissionDisplay from "./MultiScreen";
import QueueDisplayDefault from "./withoutMultiScreen";


const QueueDisplay = () => {
  const isMultiScreen = useCheckConfig('isMultiScreen');
  return (
    isMultiScreen ?
      <QueueAdmissionDisplay /> :
      <QueueDisplayDefault />
  );
};

export default QueueDisplay;
