import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import {
  AdminDisplay,
  Home,
  Login,
  PatientDisplay,
  PatientLablePrint,
  QueueDisplay,
  RegistrationDisplay,
  QueueAdmissionDisplay,
  AdminDisplayPoliclinic,
  QueuePoliclinicDisplay,
  ConfigDisplayAdmission,
  ConfigDisplayPoliclinic,
  PatientDisplayAdmission,
  PatientDisplayPoliclinic,
  PatientDisplayDoctor
} from "./pages";
import { isAntrol } from "./helpers/kioskConfigs";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/patient-display/:machineId"
          element={
            <PrivateRoute>
              <PatientDisplay />
            </PrivateRoute>
          }
        />
        <Route
          path="/patient-display/print/:secret"
          element={
            <PrivateRoute>
              <PatientLablePrint />
            </PrivateRoute>
          }
        />
        <Route
          path="/queue-display/:machineId"
          element={
            <PrivateRoute>
              <QueueDisplay />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin-display/:machineId/:counterId"
          element={
            <PrivateRoute>
              <AdminDisplay />
            </PrivateRoute>
          }
        />
      {
        // routes for isAntrol config
        isAntrol && (
        <>
        <Route
          path="/queue-display/admission/:machineId"
          element={
            <PrivateRoute>
              <QueueDisplay />
            </PrivateRoute>
          }
        />
        <Route
          path="/queue-display/policlinic/:machineId"
          element={
            <PrivateRoute>
              <QueuePoliclinicDisplay />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin-display/policlinic/:machineId/:counterId"
          element={
            <PrivateRoute>
              <AdminDisplayPoliclinic />
            </PrivateRoute>
          }
        />

        {
          // page locket antrol
          <Route
            path="/admin-display/admission/:machineId/:counterId"
            element={
              <PrivateRoute>
                <AdminDisplay />
              </PrivateRoute>
            }
          />
        }

        <Route
          path="/admin-display/admission/:machineId/:counterId"
          element={
            <PrivateRoute>
              <AdminDisplay />
            </PrivateRoute>
          }
        />
        <Route
          path="/config-display/admission/:machineId"
          element={
            <PrivateRoute>
              <ConfigDisplayAdmission />
            </PrivateRoute>
          }
        />
        <Route
          path="/config-display/policlinic/:machineId"
          element={
            <PrivateRoute>
              <ConfigDisplayPoliclinic />
            </PrivateRoute>
          }
        />
        </>
      )}
        <Route
          path="/home"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />

        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate to="/login" replace />} />

        <Route
          path="/patient-display/:machineId/:itemId"
          element={
            <PrivateRoute>
              <RegistrationDisplay />
            </PrivateRoute>
          }
        />

        <Route
          path="/patient-display/admission/:machineId/"
          element={
            <PrivateRoute>
              <PatientDisplayAdmission />
            </PrivateRoute>
          }
        />


        <Route
          path="/patient-display/policlinic/:machineId"
          element={
            <PrivateRoute>
              <PatientDisplayPoliclinic />
            </PrivateRoute>
          }
        />

        <Route
          path="/patient-display/policlinic/:machineId/:poliId"
          element={
            <PrivateRoute>
              <PatientDisplayDoctor />
            </PrivateRoute>
          }
        />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
