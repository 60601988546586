
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import "../index.css";
import { Col, Row } from "react-bootstrap";
import Pagination from "../../../components/Pagination";
import BackButton from "../../../components/BackButton";
import Button from "../../../components/Button";
import Topbar from "../../../components/Topbar/index.";
import { createQueueProcessV2, getDoctorsHospitals } from "../../../actions";
import PatientPreviewPoliclinic from "../Preview/printPoliClinicPreview";
import moment from "moment";
import { encode as base64_encode } from "base-64";


const Doctor = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const itemsPerPage = 6;

    const { machineId, poliId } = useParams()

    // print
    let componentRef = useRef(null);

    const [dataEncoded, setDataEncoded] = useState();

    // declare state
    const [dataPrint, setDataPrint] = useState({
        // poliName: null,
        doctorName: null,
        // scheduleDoctor: null,
    })

    //selector
    const doctorsHospitals = useSelector((state) => state.bookAppointment.dataDoctorsHospitals)

    const queueProcess = useSelector((state) => state.queueProcess);

    const [paginationProps, setPaginationProps] = useState({
        page: 1,
        limit: itemsPerPage,
        totalData: doctorsHospitals.length,
    });

    const [poliName, setPoliName] = useState('')

    const handlePageChange = (page, limit) => {
        setPaginationProps((prev) => ({ ...prev, page, limit }));
    };

    const currentClinics = () => {

        let dayNow = moment().day()
        if (dayNow === 0) dayNow = 7 //sunday

        const hospitalDoctor = []

        doctorsHospitals.forEach((el) => {
            if ((el?.scheduleList?.tm_poli.id === poliId) && (el?.leave === false) && (el?.scheduleList?.tm_doctor_schedules_v2s?.[0])) {
                const scheduleDetail = el?.scheduleList?.tm_doctor_schedules_v2s?.[0]
                if (scheduleDetail?.tm_doctor_schedule_items) {
                    const scheduleItems = (scheduleDetail && scheduleDetail.tm_doctor_schedule_items) ? scheduleDetail?.tm_doctor_schedule_items?.find(item => item?.day === dayNow) : {}
                    if (scheduleItems) {
                        const startOf = scheduleItems.from_time ? moment(scheduleItems.from_time, 'HH:mm:ss').format('HH:mm') : null
                        const endOf = scheduleItems.to_time ? moment(scheduleItems.to_time, 'HH:mm:ss').format('HH:mm') : null

                        hospitalDoctor.push({
                            ...el.scheduleList,
                            name: el.scheduleList.tm_doctor.name,
                            poliName: el.scheduleList.tm_poli.name,
                            description: startOf + " - " + endOf,
                            queuePrefix: el.scheduleList.tm_poli.prefix + el.scheduleList.prefix
                        })
                    }
                }
            }
        })

        return hospitalDoctor ? hospitalDoctor.slice(
            (paginationProps.page - 1) * paginationProps.limit,
            paginationProps.page * paginationProps.limit
        ) : []
    };


    const handleBack = () => {
        navigate(-1);
    }

    const handleNext = () => {
        navigate('')
    }

    useEffect(() => {
        const schedule_date = moment().format("YYYY-MM-DD");
        const hospitalId = localStorage.getItem("hospitalId")
        dispatch(getDoctorsHospitals(hospitalId, schedule_date));
    }, [])

    useEffect(() => {
        setPaginationProps((prev) => ({ ...prev, totalData: currentClinics().length }));
        setPoliName(doctorsHospitals ? doctorsHospitals.find(el => el?.scheduleList?.tm_poli?.id === poliId)?.scheduleList?.tm_poli?.name : '')
    }, [doctorsHospitals])

    const handleCreate = async (doctorHospitalId, prefix) => {

        // Get the current time
        const hourlyNow = moment();

        // Calculate the start of the range (current hour)
        const startOfRange = hourlyNow.startOf('hour');

        // Calculate the end of the range (next hour)
        const endOfRange = moment(startOfRange).add(1, 'hour');

        // If the current time has passed the end of the range, shift to the next hour
        if (hourlyNow.isSameOrAfter(endOfRange)) {
            startOfRange.add(1, 'hour');
            endOfRange.add(1, 'hour');
        }

        let body = {
            queueMachine: machineId,
            poliId: poliId,
            doctorHospitalId: doctorHospitalId,
            scheduleDoctor: `${startOfRange.format('HH:mm')}-${endOfRange.format('HH:mm')}`,
            prefix: prefix
        };

        return new Promise((resolve, reject) => {
            dispatch(createQueueProcessV2(body))
                .then((response) => {
                    if (response.status === 200) {
                        setTimeout(() => {
                            resolve()
                        }, 1000);
                    } else {
                        reject()
                    }
                });
        });
    };


    const handleCreateAndPrint = (clinic) => {
        setDataPrint((prev) => (
            {
                ...prev,
                doctorName: clinic.name,
            }
        ))

        handleCreate(clinic.id, clinic.queuePrefix)
        .then((el) => {
            handlePrintAsync()
                .then(() => {
                    console.log('Print completed');
                    setTimeout(() => {
                        navigate(`/patient-display/admission/${machineId}`);
                    }, 1000);
                });
        })
        .catch((error) => {
            console.error("Error dispatching: ", error);
        });
    
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onBeforePrint: () => {
            console.log("BEFORE");
        },
    })

 
    const handlePrintAsync = () => {
        return new Promise((resolve, reject) => {
            try {
                handlePrint();
                resolve(); // Resolve the promise once handlePrint is called
            } catch (error) {
                reject(error); // Reject the promise if there is an error
            }
        });
    };




    useEffect(() => {
        if (queueProcess.createQueueProcessV2) {
            const encodedData = base64_encode(
                JSON.stringify({
                    ...queueProcess.createQueueProcessV2,
                    ...dataPrint,
                })
            );
            setDataEncoded(encodedData);

        }
    }, [queueProcess.createQueueProcessV2]);

    const navigatePatientDisplay = () => {
        navigate(`/patient-display/admission/${machineId}`);
    }


    return (
        <>
            <div className="patient-bg font-family-patient-display" style={{ justifyContent: 'space-between' }}>
                <div className="patient-header">
                    <Topbar />
                </div>

                <Row className="m-0 mb-4 d-flex justify-content-center" >
                    <Col md={11}>
                        <Row className="justify-content-center title-hospital-service">
                            <h2>Silakan pilih Dokter Spesialis {poliName} untuk konsultasi</h2>
                        </Row>
                    </Col>
                </Row>

                <div className="patient-container" style={{ flex: 'none' }}>

                    <div className="clinic-selection-screen">

                        <div className="clinic-grid">
                            {currentClinics().map((clinic) => (
                                <Button
                                    key={clinic.id}
                                    title={clinic.name}
                                    description={"Jadwal Praktek" + " " + clinic.description}
                                    onClick={() => handleCreateAndPrint(clinic)}
                                    className="clinic-button-doctor"
                                />
                            ))}
                        </div>
                    </div>
                </div>

                <div className="pagination-controls">
                    <Col sm={6} className="text-center">
                        <Row>
                            <Col>
                                <BackButton handleBack={handleBack} />
                            </Col>
                            <Col>
                                <Button
                                    title={'Ke Halaman Utama'}
                                // description={"Jadwal Praktek" + " " + clinic.description}
                                    onClick={() => navigatePatientDisplay()}
                                className="button-back fw-bold fs-30 cursor-pointer ml-04 title-back-button"
                                />
                            </Col>
                        </Row>
                       
                    </Col>
                    {
                        paginationProps?.totalData && <Pagination
                            style={{ marginTop: '24px', marginBottom: '8px' }}
                            currData={currentClinics()}
                            props={paginationProps}
                            setProps={setPaginationProps}
                            onChange={handlePageChange}
                        />
                    }
                </div>
            </div>
            <div style={{ display: "none" }}>
                <PatientPreviewPoliclinic ref={componentRef} data={dataEncoded} />
            </div>
        </>
    );
}

export default Doctor;