
export const retrieveLocalStorageItem = (storageKey) => {
    try {
      if (!storageKey) return null;
      const value = localStorage.getItem(storageKey);
      const formatValue = JSON.parse(value);
      return formatValue;
    } catch (error) {
      return error;
    }
  };