export const PREFIX_M_JKN = 'MJ'

export const PAYER_ID = {
    BPJS : '1',
    PRIBADI : '2',
    ASURANSI : '3',
    PERUSAHAAN : '4',
    CASH_DEPOSIT : '5'
}

export const GeneralTimeoutRealtime = 500;

export const CONSULTATION_TYPE_ID = {
    REGULER: 1,
    BPJS: 2,
    TELEKONSULTASI: 3
}

export const QUEUE_DISPLAY = {
    ADMISSION : 'Admission',
    POLICLINIC : 'Policlinic',
}