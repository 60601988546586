import { queueProcessConstants } from "../actions/constants";

const initState = {
  allQueueProcess: null,
  dashboard: [],
  queueProcess: null,
  nextQueueProcess: null,
  createQueueProcess: null,
  createQueueProcessV2 : null,
  allLoading: false,
  loading: false,
  nextLoading: false,
  createLoading: false,
  createLoadingV2: false,
  allError: null,
  error: null,
  nextError: null,
  createError: null,
  createErrorV2 : null,
  queueProcessSkipped: null,
  updateQueueProcessSkipped: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case queueProcessConstants.GET_ALL_QUEUE_PROCESS_REQUEST:
      state = {
        ...state,
        allLoading: true,
      };
      break;
    case queueProcessConstants.GET_ALL_QUEUE_PROCESS_SUCCESS:
      state = {
        ...state,
        allQueueProcess: action.payload,
        allLoading: false,
      };
      break;
    case queueProcessConstants.GET_ALL_QUEUE_PROCESS_FAILURE:
      state = {
        ...state,
        allQueueProcess: null,
        allError: action.payload,
        allLoading: false,
      };
      break;
    case queueProcessConstants.GET_QUEUE_PROCESS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case queueProcessConstants.GET_QUEUE_PROCESS_SUCCESS:
      state = {
        ...state,
        queueProcess: action.payload,
        loading: false,
      };
      break;
    case queueProcessConstants.GET_QUEUE_PROCESS_FAILURE:
      state = {
        ...state,
        queueProcess: null,
        error: action.payload,
        loading: false,
      };
      break;
    case queueProcessConstants.NEXT_CALL_QUEUE_PROCESS_REQUEST:
      state = {
        ...state,
        nextLoading: true,
      };
      break;
    case queueProcessConstants.NEXT_CALL_QUEUE_PROCESS_SUCCESS:
      state = {
        ...state,
        nextQueueProcess: action.payload,
        nextLoading: false,
      };
      break;
    case queueProcessConstants.NEXT_CALL_QUEUE_PROCESS_FAILURE:
      state = {
        ...state,
        nextQueueProcess: null,
        nextError: action.payload,
        nextLoading: false,
      };
      break;
    case queueProcessConstants.CREATE_QUEUE_PROCESS_REQUEST:
      state = {
        ...state,
        createLoading: true,
      };
      break;
    case queueProcessConstants.CREATE_QUEUE_PROCESS_SUCCESS:
      state = {
        ...state,
        createQueueProcess: action.payload,
        createLoading: false,
      };
      break;
    case queueProcessConstants.CREATE_QUEUE_PROCESS_FAILURE:
      state = {
        ...state,
        createQueueProcess: null,
        createError: action.payload,
        createLoading: false,
      };
      break;
    case queueProcessConstants.CREATE_QUEUE_PROCESS_REQUEST_V2:
      state = {
        ...state,
        createLoadingV2: true,
      };
      break;
    case queueProcessConstants.CREATE_QUEUE_PROCESS_SUCCESS_V2:
      state = {
        ...state,
        createQueueProcessV2: action.payload,
        createLoadingV2: false,
      };
      break;
    case queueProcessConstants.CREATE_QUEUE_PROCESS_FAILURE_V2:
      state = {
        ...state,
        createQueueProcessV2: null,
        createErrorV2: action.payload,
        createLoadingV2: false,
      };
      break;
    case queueProcessConstants.GET_ALL_SKIPPED_QUEUE_PROCESS_REQUEST:
      state = {
        ...state,
        allLoading: true,
      };
      break;
    case queueProcessConstants.GET_ALL_SKIPPED_QUEUE_PROCESS_SUCCESS:
      state = {
        ...state,
        queueProcessSkipped: action.payload,
        allLoading: false,
      };
      break;
    case queueProcessConstants.GET_ALL_SKIPPED_QUEUE_PROCESS_FAILURE:
      state = {
        ...state,
        queueProcessSkipped: null,
        allError: action.payload,
        allLoading: false,
      };
      break;
    case queueProcessConstants.UPDATE_SKIPPED_QUEUE_PROCESS_REQUEST:
      state = {
        ...state,
        allLoading: true,
      };
      break;
    case queueProcessConstants.UPDATE_SKIPPED_QUEUE_PROCESS_SUCCESS:
      state = {
        ...state,
        updateQueueProcessSkipped: action.payload,
        allLoading: false,
      };
      break;
    case queueProcessConstants.UPDATE_SKIPPED_QUEUE_PROCESS_FAILURE:
      state = {
        ...state,
        updateQueueProcessSkipped: null,
        allError: action.payload,
        allLoading: false,
      };
      break;
    case queueProcessConstants.GET_DASHBOARD_REQUEST:
      state = {
        ...state,
        allLoading: true,
      };
      break;
    case queueProcessConstants.GET_DASHBOARD_SUCCESS:
      state = {
        ...state,
        dashboard: action.payload,
        allLoading: false,
      };
      break;
    case queueProcessConstants.GET_DASHBOARD_FAILURE:
      state = {
        ...state,
        dashboard: null,
        allError: action.payload,
        allLoading: false,
      };
      break;
    default:
      break;
  }
  return state;
};
