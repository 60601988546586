import axios from "../helpers/axios";
import { authConstants } from "./constants";

export const login = (body) => {
  return async (dispatch) => {
    dispatch({ type: authConstants.LOGIN_REQUEST });

    const res = await axios.post("/api/auth/login", body).catch((err) => {
      console.log(err);
      return dispatch({
        type: authConstants.LOGIN_FAILURE,
        payload: err.response.data.errorMessage,
      });
    });

    if (res.status === 200) {
      const { accessToken, refreshToken, configs, hospitalId , accessTokenHis, logo, hospital } = res.data;

      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("username", body.username);
      localStorage.setItem("configs", configs)
      localStorage.setItem("hospitalId", hospitalId)
      localStorage.setItem("accessTokenHis", accessTokenHis)
      hospital && localStorage.setItem("hospital", hospital)

      dispatch({
        type: authConstants.LOGIN_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  };
};
