import React, { useEffect, useState } from "react";
import moment from "moment";
import { decode as base64_decode } from "base-64";
import useCheckConfig from "../../../hooks/useCheckConfig";

const PatientLablePreview = React.forwardRef((props, ref) => {
  const [data, setData] = useState();

  const checkConfigPatientDisplay = useCheckConfig('enablePatientDisplay');
  const checkConfigPatientDisplayV2 = useCheckConfig('enablePatientDisplayV2');

  useEffect(() => {
    if (props.data) {
      const decodedData = base64_decode(props.data)
        ? JSON.parse(base64_decode(props.data))
        : "";
      setData(decodedData);
    }
  }, [props.data]);

  const pixelTo = {
    mm: (pixel) => parseFloat(pixel) * 0.264583 + "mm",
    cm: (pixel) => parseFloat(pixel) * 0.0264583 + "cm",
    pt: (pixel) => parseFloat(pixel) * 0.75 + "pt",
  };


  return (
      <div ref={ref}>
        <table className="kiosk-font-face">
          <thead>
            <tr>
              <th style={{ fontSize: pixelTo.pt("16px"), letterSpacing: pixelTo.pt("1px"), fontWeight: "900", textAlign: "center" }}>
                {data?.hospital?.name ? data.hospital.name : "-"}
                {/* Siloam Clinic Galaxy */}
              </th>
            </tr>          
          </thead>
          <tbody>
            <tr>
              <td style={{ fontSize: pixelTo.pt("10px"), letterSpacing: pixelTo.pt("1px"), fontWeight: "700", textAlign: "center" }}>
                {data?.queueDate ? moment(data.queueDate).format("DD-MM-YYYY") : "-"} &nbsp;&nbsp; {data?.queueTime ? data.queueTime : "-"}
                {/* 26-12-2023    12:22:04 */}
              </td>
            </tr>
            <tr>
              <td style={{ fontSize: pixelTo.pt( checkConfigPatientDisplayV2 ? "8px": "14px"), letterSpacing: pixelTo.pt("1px"), fontWeight: "900", textAlign: "center" }}>
                {checkConfigPatientDisplayV2 ? "Antrian Pendaftaran Rawat Jalan" :data?.queueItem?.name ? data.queueItem.name : "-"}
                {/* Ambil Antrian */}
              </td>
            </tr>
            <tr>
              <td style={{ fontSize: pixelTo.pt( checkConfigPatientDisplayV2 ? "8px" : "12px"), letterSpacing: pixelTo.pt("1px"), fontWeight: "700", textAlign: "center" }}>
                Nomor Antrian
              </td>
            </tr>
            <tr>
              <td style={{ fontSize: pixelTo.pt("24px"), letterSpacing: pixelTo.pt("1px"), fontWeight: "900", textAlign: "center" }}>
                {data?.queuePrefixNumber ? data.queuePrefixNumber : "-"}
                {/* K-15 */}
              </td>
            </tr>
            {
            checkConfigPatientDisplay && !checkConfigPatientDisplayV2 &&
            <tr>
              <td style={{ fontSize: "10px", letterSpacing: "1px", fontWeight: "500", textAlign: "center" }}>
                {data?.poliName ? data.poliName : "-"}
              </td>
            </tr>
          }
          {
            checkConfigPatientDisplay && !checkConfigPatientDisplayV2 &&
            <tr>
              <td style={{ fontSize: "10px", letterSpacing: "1px", fontWeight: "500", textAlign: "center" }}>
                {data?.doctorName ? data.doctorName : "-"}
              </td>
            </tr>
          }
          {
            checkConfigPatientDisplay && !checkConfigPatientDisplayV2 &&
            <tr>
              <td style={{ fontSize: "10px", letterSpacing: "1px", fontWeight: "500", textAlign: "center" }}>
                Estimasi dilayani: {data?.scheduleDoctor ? data.scheduleDoctor : "-"}
              </td>
            </tr>
          }
            <tr>
            <td style={{ fontSize: pixelTo.pt("8px"), marginTop: pixelTo.pt("4px"), letterSpacing: pixelTo.pt("1px"), fontWeight: "700", textAlign: "center" , marginBottom: '12px'}}>
              {
                checkConfigPatientDisplay && !checkConfigPatientDisplayV2 ? "Jika Anda melewatkan giliran Anda, mohon untuk mengambil nomor antrian yang baru."
                  : !checkConfigPatientDisplayV2 ? "*Silahkan mengambil nomor antrian baru jika nomor antrian terlewatkan" : '*Pendaftaran rawat jalan dibuka 1 jam sebelum layanan poliklinik dimulai'
              }
            </td>
            </tr>
          </tbody>
        </table>
      </div>
  );
});

export default PatientLablePreview;
