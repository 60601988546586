import { masterDataConstants } from '../actions/constants';

const initState = {
  dataDoctorsHospitals: [],
  poli: [],
  loading: false,
  error: null,
  schedules : []
};

export default (state = initState, action) => {
    switch (action.type) {
        case masterDataConstants.GET_DOCTOR_HOSPITAL_REQUEST:
            state = {
                ...state,
                loading: true,
            };
            break;
        case masterDataConstants.GET_DOCTOR_HOSPITAL_SUCCESS:
            state = {
                ...state,
                dataDoctorsHospitals: action.payload.data,
                loading: false,
            };
            break;
        case masterDataConstants.GET_DOCTOR_HOSPITAL_FAILURE:
            state = {
                ...state,
                error: action.payload.error,
                loading: false,
            };
            break;
        case masterDataConstants.GET_DOCTOR_HOSPITAL_CLEAR: 
            state = {
                ...state,
                dataDoctorsHospitals: [],
                loading: false,
            };
            break;
        case masterDataConstants.GET_POLI_REQUEST:
            state = {
                ...state,
                loading: true,
            };
            break;
        case masterDataConstants.GET_POLI_SUCCESS:
            state = {
                ...state,
                poli: action.payload.data,
                loading: false,
            };
            break;
        case masterDataConstants.GET_POLI_FAILURE:
            state = {
                ...state,
                error: action.payload.error,
                loading: false,
            };
            break;
        case masterDataConstants.GET_SCHEDULE_REQUEST:
            state = {
                ...state,
                loading: true,
            };
            break;
        case masterDataConstants.GET_SCHEDULE_SUCCESS:
            state = {
                ...state,
                schedules: action.payload.data,
                loading: false,
            };
            break;
        case masterDataConstants.GET_SCHEDULE_FAILURE:
            state = {
                ...state,
                error: action.payload.error,
                loading: false,
            };
            break;
        case masterDataConstants.GET_SCHEDULE_CLEAR: 
            state = {
                ...state,
                schedules: [],
                loading: false,
            };
            default:
            return state;
    }
    return state;
};
