import { useEffect, useState, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Image, Row } from "react-bootstrap";
import Button from "../../../components/Button";
import CustomDropdown from "../../../components/CustomDropdown";
import { HiArrowSmLeft } from "react-icons/hi";
import { 
    clearQueueItem, 
    clearSchedule, 
    createQueueProcess, 
    getAllPoli, 
    getDoctorsHospitals, 
    getPoliBpjs, 
    getQueueItem,
    getSchedules,
    getSlotQueue
    } from "../../../actions";

import { PAYER_ID } from "../../../varConstant";
import moment from "moment";

import { encode as base64_encode } from "base-64";
import { useReactToPrint } from 'react-to-print';
import PatientLablePreview from "../Preview";
import Modal from "../../../components/Modal";

const RegistrationDisplay = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const { itemId, machineId } = useParams();

    let componentRef = useRef(null);
    const hospitalId = localStorage.getItem("hospitalId")

    // redux
    const dataQueueItem = useSelector((state) => state.queueItem.queueItem);
    const doctorsHospitals = useSelector((state) => state.bookAppointment.dataDoctorsHospitals)
    const dataPoli = useSelector((state) => state.bookAppointment.poli)
    const schedules = useSelector((state) => state.bookAppointment.schedules)
    const queueProcess = useSelector((state) => state.queueProcess);
    
    // declare state
    const [dataPrint, setDataPrint] = useState({
        poliName: null,
        doctorName : null,
        scheduleDoctor : null, 
    })

    const [poliId, setPoliId] = useState("")
    const [doctorHospitalId, setDoctorDoctorHospitalid] = useState("")
    const [scheduleId, setscheduleId] = useState('');
    const [countQueueProcess, setCountQueueProcess] = useState(0)
    const [modal, setModal] = useState(false)

    const [dataEncoded, setDataEncoded] = useState();

    // schedule
    const [isOpen, setIsOpen] = useState(false);
    // doctor
    const [isOpenDoctor, setIsOpenDoctor] = useState(false)
    // poli 
    const [isOpenDoctorPoli, setIsOpenDoctorPoli] = useState(false)

    useEffect(() => {
        if (dataQueueItem && (dataQueueItem?.payerId === PAYER_ID.BPJS)) {
            dispatch(getPoliBpjs())
        } else {
            dispatch(getAllPoli())
        }
    }, [dispatch, dataQueueItem])

    useEffect(() => {
        const schedule_date = moment().format("YYYY-MM-DD");
        dispatch(getQueueItem(itemId));
        dispatch(getDoctorsHospitals(hospitalId, schedule_date));
    }, []);

    useEffect(() => {
        const schedule_date = moment().format("DD-MM-YYYY");
        const selectDoctorName = (doctorsHospitals &&
            doctorsHospitals?.filter(
                (e) => poliId === e?.scheduleList?.tm_poli?.id && doctorHospitalId === e?.scheduleList?.id
            )[0]?.scheduleList?.id)

        const form = {
            doctor_id: selectDoctorName,
            doctor_hospital_id: doctorHospitalId || null,
            date: schedule_date,
            all_doctor: 1,
            detail_slot: 0,
        };

        if (dataQueueItem && (dataQueueItem?.payerId === PAYER_ID.BPJS)) {
            form.consultation_type = 2;
        } else {
            form.consultation_type = 1;
        }

        if (form.doctor_id) {
            dispatch(getSchedules(form));
        }
    }, [doctorHospitalId])
  
    useEffect(() => {
        if (queueProcess.createQueueProcess) {
          const encodedData = base64_encode(
            JSON.stringify({
                ...queueProcess.createQueueProcess, 
                ...dataPrint, 
            })
          );
          setDataEncoded(encodedData);
          // window.open(`/patient-display/preview/${encodedData}`, "_blank");
    
        }
      }, [queueProcess.createQueueProcess]);


    // Function
    const handleBack = () => {
        dispatch(clearQueueItem())
        navigate(-1);
    };

    const formaterDataSelect = (data) => {
        return data.map((item) => {
            return { value: item.id, title: item.name };
        });
    }

    const mappedDoctorHospital = () => {
        let doctorHospital = doctorsHospitals

        if (poliId && dataQueueItem && (dataQueueItem?.payerId === PAYER_ID.BPJS)) {
            doctorHospital = Array.isArray(doctorsHospitals) ? doctorsHospitals
                .filter((item => {
                    return item.scheduleList.bpjs_code && item.scheduleList.tm_poli.id === poliId
                })) : []
        } else {
            doctorHospital = Array.isArray(doctorsHospitals) ? doctorsHospitals
            .filter((item => {
                return item.scheduleList.tm_poli.id === poliId
            })) : []
        }
        if(doctorHospital && doctorHospital.length === 0){
            return doctorHospital = [{
                key: 'not-found',
                value: '',
                title: poliId ? 'Dokter dengan poli ini belum ada' : 'Silahkan Pilih Poli',
                disabled: true
            }]
        }

        doctorHospital = doctorHospital && doctorHospital?.length > 0 ? doctorHospital
            .map((data, index) => ({
                key: `${data.scheduleList?.bpjs_code}_${index}`,
                value: data.scheduleList.id,
                title: `${data.scheduleList.tm_doctor.name} - ${data.scheduleList.tm_specialization.name} - ${data.scheduleList.tm_poli.name}`,
                bpjs_code: data.scheduleList?.bpjs_code,
                name: data.scheduleList.tm_doctor.name,
                disabled: data.leave,
            })) : []

        return doctorHospital

    } 

   
    const handleChangePoli = (e) => {
        if (schedules) {
            dispatch(clearSchedule())
        }
        if (e) {
            setPoliId(e.value)
            setDataPrint({
                ...dataPrint,
                poliName: e.title
            })
        }
        setDoctorDoctorHospitalid("")
        setscheduleId("")
    }

    const handleChangeDoctor = (e) => {
        if (schedules) {
            dispatch(clearSchedule())
        }

        if (e) {
            setDoctorDoctorHospitalid(e.value)
            setDataPrint({
                ...dataPrint,
                doctorName: e.title
            })
        }
        setscheduleId("")
    }

    const handleChangeSchedule = (e) => {
        if (e) {
            setscheduleId(e.value)
            setDataPrint({
                ...dataPrint,
                scheduleDoctor: e.title
            })
        }
        
        let body = {
            queueMachine: machineId,
            queueItemId: itemId,
            poliId: poliId,
            doctorHospitalId: doctorHospitalId,
            scheduleDoctor: e.value,
        };
        dispatch(getSlotQueue(body)).then((data)=> {
            setCountQueueProcess(data?.countQueueProcess)
            const findQuota = schedules?.[0].quota
            if(data?.countQueueProcess === findQuota){
                setModal(true)
            }
        })
    }

    const dataS = useCallback(()=> {
        const formConsultationDate = (moment().format("DD-MM-YYYY"))
        const todayHourFormatted = (moment().format("HH:mm"))
        const dataSchedule = schedules &&
            (schedules.length > 0) &&
            schedules.map((data) =>{ 
            return {
                label: data.consultation_name,
                options:
                    data && data.slot &&
                    data.slot.map((slot) => ({
                        key: data.consultation_name + slot.time,
                        value: slot.time,
                        title: slot.time,
                        label: data.doctor_schedule_id,
                        disabled: 
                            data.is_waiting_list
                                ? false
                                : slot.full
                                    ? true
                                    : formConsultationDate && slot.time.split("-")[1] < todayHourFormatted && slot.time.split("-")[1] !== "00:00"
                                        ? true
                                        : scheduleId === slot.time ? countQueueProcess === data.quota :false ,
                    })),
            }}) || []

        if(dataSchedule && (dataSchedule.length === 0) || (dataSchedule?.[0]?.options.length === 0)) {
            return  [{
                key: 'not-found',
                value: '',
                title: poliId ?
                    doctorHospitalId ? 'Jadwal dokter hari ini tidak tersedia' :
                        'Silahkan Pilih Dokter' :
                    'Silahkan Pilih Poli',
                disabled: true
            }]
        } else {
            return dataSchedule?.[0]?.options || [];
        }
    }, [dispatch, handleChangeDoctor])

    const handleCreate = async () => {
        let body = {
            queueMachine: machineId,
            queueItem: itemId,
            poliId: poliId,
            doctorHospitalId: doctorHospitalId,
            scheduleDoctor: scheduleId,
        };

        return new Promise((resolve, reject) => {
            dispatch(createQueueProcess(body));
            setTimeout(() => {
                resolve()
            }, 1000);
        });       
      };

    const handleCreateAndPrint = () => {
        handleCreate()
        .then(() => {
            handlePrint()
            setTimeout(()=> {
                navigate(`/patient-display/${machineId}`)
            }, [500])
        }).catch((error) => {
            console.error("Erorr dispatching: ", error);
        })
    }
    
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onBeforePrint: () => {
            console.log("BEFORE");
        },
    })

    const renderModal = () => {
        return (
          <Modal
            size="md"
            show={modal}
            handleClose={() => setModal(false)}
            title="Alert"
          >
    
            <Row className="m-0 mb-5 text-center">
              <Col>
                <span>Slot pada jam tersebut sudah habis, silahkan pilih jam kunjungan yang lain</span>
              </Col>
            </Row>
    
            <Row className="m-0">
                    <Col>
                        <Button title="Tutup" onClick={() => {
                            setModal(false)
                            setscheduleId()
                        }} />
              </Col>
            </Row>
          </Modal>
        );
      };

    return (
        <div className="patient-bg font-family-patient-display" >
            <div className="patient-header">
                <Row className="m-0">
                    <Col className="text-center">
                        <Image src="/img/logo/logo-login-hospita.svg" height={"80px"} />
                    </Col>
                </Row>
            </div>
            <div className="patient-container" style={{justifyContent: 'start'}}>
                <Row className="mb-2" style={{ marginLeft: '13%' }}>
                    <Col>
                        <div className="d-flex"  >
                            <HiArrowSmLeft className="cl-green cursor-pointer" style={{fontSize:'50px'}} onClick={handleBack}/>
                            <div className="fw-bold fs-30 cursor-pointer mt-01 ml-04" onClick={handleBack}>Kembali</div>
                        </div>
                    </Col>
                </Row>

                <Row className="m-0 mb-1 d-flex justify-content-center">
                    <Col md={9} className="text-center">

                        <CustomDropdown
                            value={poliId}
                            onChange={(e) => {
                                handleChangePoli(e)
                            }
                            }
                            selectedValue={poliId}

                            setIsOpen={setIsOpenDoctorPoli}
                            isOpen={isOpenDoctorPoli}

                            defaultOptionTitle="- Pilih Poli -"
                            options={formaterDataSelect(dataPoli)}
                            // className={("select-control-h-default")}
                            className="custom-dropdown"
                            classNameOption="options-container-doctor"
                        />

                    </Col>
                </Row>
                <Row className="m-0 mb-1 d-flex justify-content-center">
                    <Col md={9} className="text-center">
                        <CustomDropdown
                            value={doctorHospitalId}
                            selectedValue = {doctorHospitalId}
                            onChange={(e) => handleChangeDoctor(e)}

                            
                            setIsOpen={setIsOpenDoctor}
                            isOpen={isOpenDoctor}
                            
                            defaultOptionTitle="- Pilih Dokter -"
                            options={mappedDoctorHospital()}
                            className="custom-dropdown"
                            classNameOption="options-container-doctor"
                        />
                    </Col>
                </Row>
                
                <Row className="m-0 mb-1 d-flex justify-content-center">
                    <Col md={9} className="text-center">
                        <CustomDropdown
                            defaultOptionTitle="- Pilih Jam Kunjungan -"
                            options={dataS()}
                            className="custom-dropdown"
                            selectedValue = {scheduleId}
                            onChange={(e) => handleChangeSchedule(e)}
                            setIsOpen={setIsOpen}
                            isOpen={isOpen}
                            classNameOption="options-container"
                        />
                    </Col>
                </Row>

                <Row className="m-0 mb-1 d-flex justify-content-center" >
                    <Col md={9} className="text-center">
                        <Button
                            className={(poliId && doctorHospitalId && scheduleId) ? "button-control-h-100" : "button-control-h-100 background-print" }
                            title={(
                                <>
                                    Cetak Nomor Antrean
                                    <Image src="/img/icon/icon-print.svg" height={"36px"} style={{ marginLeft: '20px' }} />
                                </>
                            )}
                            disabled={(poliId && doctorHospitalId && scheduleId) ? false : true}
                            onClick={() => handleCreateAndPrint()}
                        >
                        </Button>
                    </Col>
                </Row>
            </div>
            <div style={{ display: "none" }}>
                <PatientLablePreview ref={componentRef} data={dataEncoded} />
            </div>
            {renderModal()}
        </div>
    );
}

export default RegistrationDisplay;