import { retrieveLocalStorageItem } from "./localStorage";


const kioskRole = retrieveLocalStorageItem("configs");

let isAntrol;

if(kioskRole) {
  isAntrol = kioskRole.isAntrol;
}

export { kioskRole, isAntrol }