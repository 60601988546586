import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Document,
  Font,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import moment from "moment";
import { decode as base64_decode } from "base-64";

const PatientLablePrint = () => {
  const { secret } = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    if (secret) {
      const decodedData = base64_decode(secret)
        ? JSON.parse(base64_decode(secret))
        : "";
      setData(decodedData);
    }
  }, []);

  Font.register({
    family: "Montserrat",
    fonts: [
      {
        src: "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap",
      },
    ],
  });

  const styles = StyleSheet.create({
    section: {
      width: "219px",
      padding: "5px",
    },
    flexRow: {
      display: "flex",
      flexDirection: "row",
    },
    viewer: {
      width: "100%",
      height: "calc(100vh - 10px)",
    },
  });

  return (
    <PDFViewer style={styles.viewer}>
      <Document>
        <Page size="A4">
          <View style={styles.section}>
            <View style={{ ...styles.flexRow, marginBottom: "5px" }}>
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {data?.hospital?.name ? data.hospital.name : "-"}
                </Text>
              </View>
            </View>

            <View
              style={{
                ...styles.flexRow,
                marginBottom: "5px",
                marginLeft: "50px",
                marginRight: "50px",
              }}
            >
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontSize: "10px",
                    marginBottom: "2px",
                    textAlign: "center",
                  }}
                >
                  {data?.queueDate
                    ? moment(data.queueDate).format("DD-MM-YYYY")
                    : "-"}
                </Text>
              </View>

              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontSize: "10px",
                    marginBottom: "2px",
                    textAlign: "center",
                  }}
                >
                  {data?.queueTime ? data.queueTime : "-"}
                </Text>
              </View>
            </View>

            <View style={{ ...styles.flexRow, marginBottom: "5px" }}>
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontSize: "12px",
                    fontWeight: "900",
                    textAlign: "center",
                  }}
                >
                  {data?.queueItem?.name ? data.queueItem.name : "-"}
                </Text>
              </View>
            </View>

            <View style={{ ...styles.flexRow, marginBottom: "10px" }}>
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontSize: "10px",
                    textAlign: "center",
                  }}
                >
                  Nomor Antrian
                </Text>
              </View>
            </View>

            <View style={{ ...styles.flexRow, marginBottom: "10px" }}>
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {data?.queuePrefixNumber ? data.queuePrefixNumber : "-"}
                </Text>
              </View>
            </View>

            <View
              style={{
                ...styles.flexRow,
                marginLeft: "30px",
                marginRight: "30px",
                fontStyle: "italic",
              }}
            >
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontSize: "8px",
                    textAlign: "center",
                  }}
                >
                  *Silahkan mengambil nomor antrian baru jika nomor antrian
                  terlewatkan
                </Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default PatientLablePrint;
