import axios from "../helpers/axios";
import { queueItemConstants } from "./constants";

export const getQueueItem = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: queueItemConstants.GET_QUEUE_ITEM_REQUEST });

            const res = await axios.get(`/api/queue-item/${id}`).catch((err) => {
                return dispatch({
                    type: queueItemConstants.GET_QUEUE_ITEM_FAILURE,
                    payload: err.response.data,
                });
            });

            if (res.status === 200) {
                dispatch({
                    type: queueItemConstants.GET_QUEUE_ITEM_SUCCESS,
                    payload: res.data,
                });
                return res.data;
            }
            
        } catch (error) {
            return dispatch({
                type: queueItemConstants.GET_QUEUE_ITEM_FAILURE,
                payload: error?.response?.data,
            });
        }

    };
};

export const clearQueueItem = () => {
    return async (dispatch) => {
        return dispatch(
            {
                type: queueItemConstants.CLEAR_GET_QUEUE_ITEM,
            }
        )
    }
}
 