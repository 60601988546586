import Form from "react-bootstrap/Form";
import "./index.css";

const Select = (props) => {
  return (
    <Form.Select
      className={props.className ? props.className : "select-control"}
      value={props.value}
      onChange={props.onChange}
    >
      {props.defaultOption && (
        <option value="" disabled selected>{props.defaultOptionTitle}</option>
      )}

      {props.data?.length > 0
        ? props.data.map((data, index) => (
            <option value={data.value} key={index}>
              {data.title}
            </option>
          ))
        : null}
    </Form.Select>
  );
};

export default Select;
