import axios from "../helpers/axios";
import { maxLimit, queueProcessConstants } from "./constants";

export const getAllQueueProcess = (id) => {
  return async (dispatch) => {
    dispatch({ type: queueProcessConstants.GET_ALL_QUEUE_PROCESS_REQUEST });

    const res = await axios.get(`/api/counter-last-call/${id}`).catch((err) => {
      return dispatch({
        type: queueProcessConstants.GET_ALL_QUEUE_PROCESS_FAILURE,
        payload: err.response.data,
      });
    });

    if (res.status === 200) {
      dispatch({
        type: queueProcessConstants.GET_ALL_QUEUE_PROCESS_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  };
};

export const getQueueProcess = (machineId, counterId) => {
  return async (dispatch) => {
    dispatch({ type: queueProcessConstants.GET_QUEUE_PROCESS_REQUEST });

    const res = await axios
      .get(`/api/counter-last-call/${machineId}/${counterId}`)
      .catch((err) => {
        return dispatch({
          type: queueProcessConstants.GET_QUEUE_PROCESS_FAILURE,
          payload: err.response.data,
        });
      });

    if (res.status === 200) {
      dispatch({
        type: queueProcessConstants.GET_QUEUE_PROCESS_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  };
};

export const nextCallQueueProcess = (body) => {
  return async (dispatch) => {
    dispatch({ type: queueProcessConstants.NEXT_CALL_QUEUE_PROCESS_REQUEST });
    let url = `/api/queue-process/next-call`
    const res = await axios
      .post(url, body)
      .catch((err) => {
        return dispatch({
          type: queueProcessConstants.NEXT_CALL_QUEUE_PROCESS_FAILURE,
          payload: err.response.data,
        });
      });

    if (res.status === 200) {
      dispatch({
        type: queueProcessConstants.NEXT_CALL_QUEUE_PROCESS_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  };
};

export const createQueueProcess = (body) => {
  return async (dispatch) => {
    dispatch({ type: queueProcessConstants.CREATE_QUEUE_PROCESS_REQUEST });

    const res = await axios.post("/api/queue-process/", body).catch((err) => {
      return dispatch({
        type: queueProcessConstants.CREATE_QUEUE_PROCESS_FAILURE,
        payload: err.response.data,
      });
    });

    if (res.status === 200) {
      dispatch({
        type: queueProcessConstants.CREATE_QUEUE_PROCESS_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  };
};

export const getAllSkippedCall = (machineId, limit) => {
  return async (dispatch) => {
    dispatch({ type: queueProcessConstants.GET_ALL_SKIPPED_QUEUE_PROCESS_REQUEST });
    // const limitValue = limit || '';
    const limitValue = !limit ? null : limit ? limit : maxLimit.tvDisplayMissed;
    const res = await axios.get(`/api/counter-last-call/skip/${machineId}`, {
      params: {
        limit: limitValue
      }
    })
    .catch((err) => {
      return dispatch({
        type: queueProcessConstants.GET_ALL_SKIPPED_QUEUE_PROCESS_FAILURE,
        payload: err.response.data,
      });
    });
    if (res.status === 200) {
      dispatch({
        type: queueProcessConstants.GET_ALL_SKIPPED_QUEUE_PROCESS_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  }
}

export const updateSkippedCall = (body) => {
  return async (dispatch) => {
    dispatch({ type: queueProcessConstants.UPDATE_SKIPPED_QUEUE_PROCESS_REQUEST });
    const res = await axios.put("/api/queue-process/skip-queue/update", body)
      .catch((err) => {
        return dispatch({
          type: queueProcessConstants.UPDATE_SKIPPED_QUEUE_PROCESS_FAILURE,
          payload: err.response.data,
        });
      });
    if (res.status === 200) {
      dispatch({
        type: queueProcessConstants.UPDATE_SKIPPED_QUEUE_PROCESS_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  }
}

export const getDashboardPayer = (id) => {
  return async (dispatch) => {
    dispatch({ type: queueProcessConstants.GET_DASHBOARD_REQUEST });

    const res = await axios.get(`/api/queue-process/dashboard/${id}`).catch((err) => {
      return dispatch({
        type: queueProcessConstants.GET_DASHBOARD_FAILURE,
        payload: err.response.data,
      });
    });

    if (res.status === 200) {
      dispatch({
        type: queueProcessConstants.GET_DASHBOARD_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  };
};

export const getSlotQueue = (params) => {
  return async () => {
    try {

      const res = await axios.get(`/api/queue-process/queue-process-slot`, {
        params,
      });
  
      if (res.status === 200) {
        return res.data;
      }
    } catch ({ response }) {
      return response?.data
    }
 
  };
}

export const createQueueProcessV2 = (body) => {
  return async (dispatch) => {
    dispatch({ type: queueProcessConstants.CREATE_QUEUE_PROCESS_REQUEST_V2 });

    const res = await axios.post("/api/queue-process/V2", body).catch((err) => {
      dispatch({
        type: queueProcessConstants.CREATE_QUEUE_PROCESS_FAILURE_V2,
        payload: err.response.data,
      });
      return err.response
    });
    if (res.status === 200) {
      dispatch({
        type: queueProcessConstants.CREATE_QUEUE_PROCESS_SUCCESS_V2,
        payload: res.data,
      });
      return res;
    } else {
      return res
    }
  };
};