import axios from "axios";
import { api } from "../urlConfig";

const accessToken = localStorage.getItem("accessToken");

const axiosInstance = axios.create({
  baseURL: api,
  headers: {
    Authorization: accessToken ? `Bearer ${accessToken}` : "",
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if(error.response && error.response.status === 401) {
      localStorage.clear()
      window.location.href = '/login';
    }
    return Promise.reject(error)
  }
)

export default axiosInstance;
