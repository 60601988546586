import React, { useEffect, useState } from "react";
import { Col, Dropdown, Image, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { HiArrowSmLeft } from "react-icons/hi";
import Button from "../../../components/Button";
import Header from "../../../components/Header";
import {
  getCounter,
  getQueueMachine,
  getQueueProcess,
  nextCallQueueProcess,
  getAllSkippedCall,
  getAllQueueProcess,
  updateSkippedCall,
  getDashboardPayer,
} from "../../../actions";
import Modal from "../../../components/Modal";
import "./index.css";
import io from "socket.io-client";
import { socketBase, socketPath } from "../../../urlConfig";
import useCheckConfig from "../../../hooks/useCheckConfig";

const socket = io(socketBase, {path: socketPath}).connect();

export default function AdminDisplayAntrol(){
  const { machineId, counterId } = useParams();
  const [modal, setModal] = useState(false);

  // Room State
  const [room, setRoom] = useState(null);
  // Machine State
  const [machine, setMachine] = useState(null);
  // Counter State
  const [counter, setCounter] = useState(null);
  // Queue State
  const [queue, setQueue] = useState("-");
  // Skipped Queue Numbers
  const { queueProcessSkipped, allQueueProcess, dashboard } = useSelector((state) => state.queueProcess);

  const queueProcess = useSelector((state) => state.queueProcess);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const checkConfigGenerateQueueNumber = useCheckConfig('enableOneGenerateQueueNumber');

  const mapDataPayer = [
    { label: "Antrian", size: 4, value: "name", align: true },
    { label: "Belum Dilayani", size: "2", value: "belum" },
    { label: "Sudah Dilayani", size: "2", value: "sudah" },
    { label: "Terlewatkan", size: "2", value: "terlewatkan" },
    { label: "Total", size: "1", value: "total" },
  ];

  useEffect(() => {
    setRoom(machineId);
  }, [machineId]);

  useEffect(() => {
    socket.emit("join_room", room);
  }, [room]);
  
  useEffect(() => {
    dispatch(getQueueMachine(machineId))
    .then((data) => {
      setMachine(data);
    })
    .catch((err) => console.log(err));
    dispatch(getDashboardPayer(machineId)).catch((err) => console.log(err));
  }, [dispatch, machineId]);

  useEffect(() => {
    socket.on("updateDashboardQueueDisplay", () => {
      dispatch(getDashboardPayer(machineId)).catch((err) => console.log(err));
    });
  },[socket, dashboard])

  useEffect(() => {
    dispatch(getCounter(counterId))
    .then((data) => {
      setCounter(data);
    })
    .catch((err) => console.log(err));
  }, [dispatch, counterId]);

  useEffect(() => {
    dispatch(getQueueProcess(machineId, counterId))
    .then((data) => {
      if (data) {
        setQueue(data?.queuePrefixNumber);
      }
    })
    .catch((err) =>
      console.log(err)
    );
    
  }, [counterId, dispatch, machineId]);

  useEffect(() => {
    if (queueProcess.nextError) {
      setModal(true);
    }
  }, [queueProcess.nextError]);

  useEffect(() => {
    if(machineId) {
      dispatch(getAllSkippedCall(machineId))
      .catch((err) =>
      console.log(err)
      );
    }
  }, [dispatch, machineId]);

  const sendMessage = (message, payload) => {
    socket.emit("send_message", { message, room, payload });
  };

  const renderModal = () => {
    return (
      <Modal
        size="md"
        show={modal}
        handleClose={() => setModal(false)}
        title="Konfirmasi"
      >
        <Row className="m-0 text-center">
          <Col>
            <Image src="/img/icon/icon-timedate.svg" height={"100px"} />
          </Col>
        </Row>

        <Row className="m-0 mb-5 text-center">
          <Col>
            <span>Belum ada antrian</span>
          </Col>
        </Row>

        <Row className="m-0">
          <Col>
            <Button title="Tutup" onClick={() => setModal(false)} />
          </Col>
        </Row>
      </Modal>
    );
  };

  const handleVoiceCall = (queue) => {
    if (queue !== "-" && counter) {
      return `Nomor antrian ${queue}, silahkan menuju ${counter.name}`
    }
    return "";
  }

  const handleNext = (itemId, typePrefix = "") => {
    try {
      let body = {
        queueMachine: machineId,
        queueItem: itemId,
        counter: counterId,
        typePrefix : typePrefix
      };

      dispatch(nextCallQueueProcess(body))
        .then(async(data) => {
          if (data && data !== undefined) {
            var callText = handleVoiceCall(data.queuePrefixNumber);
            setQueue(data.queuePrefixNumber);
          }
          let payload = {
            skipped: queueProcessSkipped,
            process: allQueueProcess,
            text: callText
          };
      
          await dispatch(getAllSkippedCall(machineId))
            .then((res) => (payload.skipped = res))
            .catch((err) => console.log(err));
          await dispatch(getAllQueueProcess(machineId))
            .then((res) => (payload.process = res))
            .catch((err) => console.log(err));
          if(machineId){
            await dispatch(getDashboardPayer(machineId)).catch((err) => console.log(err));
          }
        socket.emit('updateAdmissionQueueDisplay' ,{ room, payload })
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error)
    }
  };

  const handleRecall = () => {
    const callText = handleVoiceCall(queue);
    const payload = {
      skipped: queueProcessSkipped,
      process: allQueueProcess,
      text: callText,
    };
    sendMessage("re-call", payload);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleSkip = async(itemId, currentQueueNumber) => {
    try {
      if(currentQueueNumber !== "-"){
        let body = {
          queueMachine: machineId,
          queueItem: itemId,
          counter: counterId,
          isQueueNumberSkipped: true,
          currentQueueNumber: currentQueueNumber
          
        };

        await dispatch(nextCallQueueProcess(body))
        .then( async (data) => {
          if (data && data !== undefined) {
            var callText = handleVoiceCall(data.queuePrefixNumber);
            setQueue(data.queuePrefixNumber);
          }
          let payload = {
            skipped: queueProcessSkipped,
            process: allQueueProcess,
            text: callText
          };
      
          await dispatch(getAllSkippedCall(machineId))
            .then((res) => (payload.skipped = res))
            .catch((err) => console.log(err));
          await dispatch(getAllQueueProcess(machineId))
            .then((res) => (payload.process = res))
            .catch((err) => console.log(err));
        
          if(machineId){
            await dispatch(getDashboardPayer(machineId)).catch((err) => console.log(err));
          }
      
        socket.emit('updateAdmissionQueueDisplay' ,{ room, payload })
        })
        .catch((err) => console.log(err));
      }
    } catch (error) {
      console.log(error)
    }
  };

  const handleCallSkip = (queueNumberId) => {
    try {
      if (queueProcessSkipped && queueProcessSkipped.length > 0) {
        let body = {
          queueProcess: queueNumberId,
          counter: counterId,
          queueMachine: machineId,
        }
        dispatch(updateSkippedCall(body))
        .then(async (data) => {
          const callText = handleVoiceCall(data.queuePrefixNumber);
          setQueue(data.queuePrefixNumber); // to show at locket
      
          let payload = {
            skipped: queueProcessSkipped,
            process: allQueueProcess,
            text: callText
          };

          await dispatch(getAllSkippedCall(machineId)) 
            .then((res) => (payload.skipped = res))
            .catch((err) => console.log(err));  // refresh get all skipped call  
          await dispatch(getAllQueueProcess(machineId))
            .then((res) => (payload.process = res))
            .catch((err) => console.log(err));

      
        socket.emit('updateAdmissionQueueDisplay' ,{ room, payload })
        })
        .catch((err) => console.log(err));
      }
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <>
      <Header className={"font-home-display"} />

      <div className="large-container font-home-display">
        <div className="large-inner-container">
          <Row className="m-0 mb-4">
            <Col>
              <span className="fw-bold fs-20">Loket {machine?.name}</span>
            </Col>
          </Row>

          <Row className="m-0 mb-4">
            <Col>
              <div onClick={handleBack} className="d-flex">
                <HiArrowSmLeft className="fs-30 cl-green cursor-pointer" />
                <div className="fw-bold fs-18 cursor-pointer mt-01 ml-04">Kembali</div>
              </div>
            </Col>
          </Row>

          <Row className="m-0 mb-4">
            <Col>
              <div className="text-center">
                <span className="fw-bold fs-25">
                  SEDANG DALAM ANTRIAN
                  </span>
              </div>
            </Col>
          </Row>

          <Row className="m-0 mb-4">
            <Col>
              <div className="desk-card-v2">
                <div className="desk-card-v2-title bg-dark">
                  <span className="fw-semi-bold fs-20 cl-white">
                    {counter?.name}
                  </span>
                </div>

                <div className="desk-card-v2-body">
                  <span className="fw-bold fs-30">
                    {queue}
                    </span>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="m-0 mb-4">
            <Col>
              <Button
                title="Panggil"
                onClick={() => handleRecall()}
              />
            </Col>
            <Col>
              {!checkConfigGenerateQueueNumber && machine?.queueItems?.length > 0 ? (
                machine?.queueItems?.length > 1 ? (
                  <Dropdown>
                    <Dropdown.Toggle className="dropdown-control">
                      Selanjutnya
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu-control">
                      {machine?.queueItems.map(
                        (data, index) => (
                          <Dropdown.Item
                            className="dropdown-item-control"
                            key={index}
                            onClick={() => handleNext(data._id, data.prefix)}
                          >
                            {data.name}
                          </Dropdown.Item>
                        )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <Button
                    title="Selanjutnya"
                    onClick={() =>
                      handleNext(machine?.queueItems[0]._id)
                    }
                  />
                )
              ) : null}

              {
                checkConfigGenerateQueueNumber && <Dropdown>
                  <Dropdown.Toggle className="dropdown-control">
                    Selanjutnya
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdown-menu-control">
                        <Dropdown.Item
                          className="dropdown-item-control"
                          onClick={() => handleNext()}
                        >
                          {"Antrian Pendaftaran Rawat Jalan"}
                        </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              }

            
            </Col>
            <Col>
              {!checkConfigGenerateQueueNumber && machine?.queueItems?.length > 0 ? (
                machine?.queueItems?.length > 1 ? (
                  <Dropdown>
                    <Dropdown.Toggle className="dropdown-control">
                      Lewatkan
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu-control">
                      {machine?.queueItems.map(
                        (data, index) => (
                          <Dropdown.Item
                            className="dropdown-item-control"
                            key={index}
                            onClick={() => handleSkip(data._id, queue)}
                          >
                            {data.name}
                          </Dropdown.Item>
                        )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <Button
                    title="Lewatkan"
                    onClick={() =>
                      handleSkip(machine?.queueItems[0]._id, queue)
                    }
                  />
                )
              ) : null}

              {
                checkConfigGenerateQueueNumber && <Dropdown>
                  <Dropdown.Toggle className="dropdown-control">
                    Lewatkan
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdown-menu-control">
                    <Dropdown.Item
                      className="dropdown-item-control"
                      onClick={() => handleSkip("", queue)}
                    >
                      {"Antrian Pendaftaran Rawat Jalan"}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              }
            </Col>
          </Row>

          <Row className="m-0 mb-4">
            <Col>
              <div className="text-center">
                <span className="fw-bold fs-25">
                  ANTRIAN TERLEWATKAN
                </span>
              </div>
            </Col>
          </Row>

          <Row className="m-0 mb-4 px-2 overflow-auto" style={{maxHeight: '25vh'}}>
            {queueProcessSkipped && queueProcessSkipped.length > 0 &&
              queueProcessSkipped.map((data) => {
                return (
                  <Col className="g-2" xs={4} lg={3} xl={2}>
                    <div className="border rounded-3 d-flex justify-content-between align-items-center p-4">
                      <div className="w-100">
                        <span className="fw-bold fs-20 d-block text-center">{data.queuePrefixNumber}</span>
                      </div>
                      <div className="cursor-pointer">
                        <Image src="/img/icon/icon-call-patient.svg" height={"30px"}
                          onClick={() => handleCallSkip(data._id)}
                        />
                      </div>
                    </div>
                  </Col>
                )
              })
              }
          </Row>

          <Row
            style={{ fontFamily: "Montserrat", fontSize: "14px" }}
            className="m-0 mb-4"
          >
            <div> Dashboard - Antrian Payer</div>
            <Row
              style={{
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "#e8edec",
                borderRadius:'10px',
                paddingLeft: 0,
                paddingRight: 0,
                marginRight: 0,
                marginLeft: 0,
              }}
            >
              <>
                <Col
                  xs={1}
                  style={{
                    backgroundColor: "rgb(153, 204, 255, 0.2)",
                    fontWeight: "bolder",
                    textAlign: "center",
                    borderRight:'1px'
                  }}
                >
                  No
                </Col>
                {mapDataPayer.map((data) => {
                  return (
                    <Col
                      xs={data.size}
                      style={{
                        backgroundColor: "rgb(153, 204, 255, 0.2)",
                        fontWeight: "bolder",
                        textAlign: data.align? data.align: 'center',
                      }}
                    >
                      {data.label}
                    </Col>
                  );
                })}
              </>

              {dashboard?.map((value, index) => {
                return (
                  <Row
                    style={{
                      paddingLeft: 0,
                      paddingRight: 0,
                      marginRight: 0,
                      marginLeft: 0,
                    }}
                  >
                    <Col
                      xs={1}
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {index + 1}
                    </Col>
                    {mapDataPayer.map((data) => {
                      return (
                        <Col
                          xs={data.size}
                          style={{
                            textAlign: data.align? data.align: 'center',
                          }}
                        >
                          {value[data.value]}
                        </Col>
                      );
                    })}
                  </Row>
                );
              })}
            </Row>
          </Row>
        </div>
        <div className="text-center mt-2">
          <span>© Copyright AIDO Health. All Rights Reserved.</span>
        </div>
      </div>
      {renderModal()}
    </>
  );
};