import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useCheckConfig from "../../hooks/useCheckConfig";
import Button from "../../components/Button";
import Topbar from "../../components/Topbar/index.";
import { QUEUE_DISPLAY } from "../../varConstant";
import { createQueueProcess } from "../../actions";
import { encode as base64_encode } from "base-64";
import "./index.css";
import { useReactToPrint } from "react-to-print";
import PatientLablePreview from "../PatientDisplay/Preview";

const PatientDisplayAdmission = () => {

    const hospital = localStorage.getItem("hospital") ? JSON.parse(localStorage.getItem("hospital")) : null;

    const checkConfigGenerateQueueNumber = useCheckConfig('enableOneGenerateQueueNumber');
    const queueProcess = useSelector((state) => state.queueProcess);

    const navigate = useNavigate()
    const { machineId } = useParams();
    const dispatch = useDispatch();
    let componentRef = useRef(null);

    const [dataEncoded, setDataEncoded] = useState();

    const handleNavigatePage = (type) => {
        if (type === QUEUE_DISPLAY.ADMISSION) {
            if (checkConfigGenerateQueueNumber) {
                return handleCreateAndPrint()
            } else {
                navigate(
                    `/patient-display/${machineId}`
                )
            }
        } else if (type === QUEUE_DISPLAY.POLICLINIC) {
            navigate(
                `/patient-display/policlinic/${machineId}`
            )
        }
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onBeforePrint: () => {
          console.log("BEFORE");
        },
      });

    const handleCreate = async () => {
        let body = {
            queueMachine: machineId,
        };
        return new Promise((resolve, reject) => {
            dispatch(createQueueProcess(body));
            setTimeout(() => {
                resolve()
            }, 1000);
        });
    };

    const handleCreateAndPrint = () => {
        handleCreate().then(() => {
            handlePrint()
        }).catch((error) => {
            console.error("Erorr dispatching: ", error);
        })
    }
    

    useEffect(() => {
        if (queueProcess.createQueueProcess) {
            const encodedData = base64_encode(
                JSON.stringify(queueProcess.createQueueProcess)
            );
            setDataEncoded(encodedData);

        }
    }, [queueProcess.createQueueProcess]);

    return (
        <>
            <div className="patient-bg font-family-patient-display" >
                <div className="patient-header">
                    <Topbar />
                </div>

                <div className="patient-container">
                    <Row className="m-0 mb-4 d-flex justify-content-center" >
                        <Col md={11}>
                            <Row className="justify-content-center title-hospital-name">
                                Selamat Datang di {
                                    hospital.name
                                }
                            </Row>
                            <Row className="justify-content-center title-hospital-service">
                                Silahkan pilih layanan yang Anda butuhkan
                            </Row>
                        </Col>
                    </Row>
                    <Row className="m-0 mb-4 d-flex justify-content-center" >
                        <Col md={11} className="text-center">
                            <Button
                                className={("button-patient-control-h-100")}
                                title={'Pendaftaran Rawat Jalan'}
                                description={'Pengambilan nomor antrian administrasi pendaftaran rawat jalan.'}
                                onClick={() => handleNavigatePage(QUEUE_DISPLAY.ADMISSION)}
                            />
                        </Col>
                    </Row>
                    <Row className="m-0 mb-4 d-flex justify-content-center" >
                        <Col md={11} className="text-center">
                            <Button
                                className={("button-patient-control-h-100")}
                                title={'Pendaftaran Poliklinik'}
                                description={'Pengambilan nomor antrian poliklinik dokter spesialis.'}
                                onClick={() => handleNavigatePage(QUEUE_DISPLAY.POLICLINIC)}
                            />
                        </Col>
                    </Row>
                </div>

            </div>
            <div style={{ display: "none" }}>
                <PatientLablePreview ref={componentRef} data={dataEncoded} />
            </div>
        </>
    )
}

export default PatientDisplayAdmission;