import { Col, Row } from "react-bootstrap";
import "./index.css";
import Topbar from "../Topbar";

const HeaderDashboard = (props) => {
  return (
    <Row className="m-0 header-dashboard">
      <Col className="header-dashboard-content">
        <Topbar height={"50px"} fontSize={props.fontSize} />
      </Col>
    </Row>
  )
};

export default HeaderDashboard;