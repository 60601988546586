import HeaderDashboard from "../../../components/HeaderDashboard";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import { Col, Row, Image } from "react-bootstrap";
import { useEffect, useState } from "react";
import InputSelect from "../../../components/InputSelect";
import Modal from "../../../components/Modal";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  createCounterAdmissionConfig,
  deleteCounterAdmissionConfig,
  getAllCounter,
  updateCounterAdmissionConfig,
} from "../../../actions";
import PopupConfirmation from "../../../components/PopupConfirmation";
import Swal from "sweetalert2";
import { HiArrowSmLeft } from "react-icons/hi";
import { doSyntax } from "../../../utils/constants";

export default function ConfigDisplayAdmission() {
  const { machineId } = useParams();
  const navigate = useNavigate()

  const [loket, setLoket] = useState(false) //true right, false left
  const { counter, loading } = useSelector((state) => state.counter);
  const [sequenceOptions, setSequenceOptions] = useState(null) // for update data
  const [sequenceOptionsCreate, setSequenceOptionsCreate] = useState(null) // for create data
  // Update Data State
  const [updateData, setUpdateData] = useState();
  // Create Data State
  const [createData, setCreateData] = useState();
  // Modal State
  const [modal, setModal] = useState(false);
  // Popup Confirmation State
  const [popupCreate, setPopupCreate] = useState(false);
  const [popupUpdate, setPopupUpdate] = useState(false);
  const [popupDelete, setPopupDelete] = useState(false);
  const [popupCancel, setPopupCancel] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllCounter())
    .then((res)=> {
      const options = res.filter((data)=> data.sequence && data.sequence > 0).map((data, index) => {
       return {
         value: index + 1,
         label: index + 1
       } 
      })
      setSequenceOptions(options)
      options.push({value: options.length + 1, label: options.length + 1})
      setSequenceOptionsCreate(options)
    })
    .catch((err) => {
      console.log(err);
    });
  }, [machineId]);

  const handleGetAllCounter = () => {
    dispatch(getAllCounter())
    .then((res)=> {
      const options = res.filter((data)=> data.sequence && data.sequence > 0).map((data, index) => {
       return {
         value: index + 1,
         label: index + 1
       } 
      })
      setSequenceOptions(options)
    })
    .catch((err) => {
      console.log(err);
    });
  }
  const handleFunction = (syntax) => {
    if (!loading) {
      switch (syntax) {
        case doSyntax.create:
          if (!createData?.name || (!createData?.sequence && createData?.sequence != 0)) {
            Swal.fire({
              icon: "error",
              text: "Silahkan lengkapi data loket"
            })
            break
          }
          dispatch(createCounterAdmissionConfig({ ...createData, active: true }))
            .then(() => {
              handleGetAllCounter()
              setUpdateData()
              setCreateData()
              setModal(false)
              Swal.fire({
                icon: "success",
                text: "Sukses tambah layar tunggu",
                timer: 3000,
              });
            })
            .catch(() => {
              handleGetAllCounter()
              setUpdateData()
              setCreateData()
              setModal(false)
              Swal.fire({
                icon: "error",
                text: "Gagal menambahkan loket"
              })
            }
            );
          break;

        // ======================================

        case doSyntax.update:
          if (!updateData?.name || !updateData?.sequence) {
            Swal.fire({
              icon: "error",
              text: "Silahkan isi nama loket dan urutan loket"
            })
            break
          }
          dispatch(updateCounterAdmissionConfig(updateData._id, { ...updateData, active: true }))
            .then(() => {
              handleGetAllCounter()
              setUpdateData()
              setCreateData()
              Swal.fire({
                icon: "success",
                text: "Sukses ganti layar tunggu",
                timer: 3000,
              });
            })
            .catch(() => {
              handleGetAllCounter()
              setUpdateData()
              setCreateData()
              Swal.fire({
                icon: "error",
                text: "Gagal mengganti layar loket"
              })
            }
            );
          break;

        // ======================================
        case doSyntax.delete:
          dispatch(deleteCounterAdmissionConfig(updateData._id))
            .then(() => {
              handleGetAllCounter()
              setUpdateData()
              setCreateData()
              Swal.fire({
                icon: "success",
                text: "Sukses menghapus layar tunggu",
                timer: 3000,
              });
            })
            .catch(() => {
              handleGetAllCounter()
              setUpdateData()
              setCreateData()
              Swal.fire({
                icon: "error",
                text: "Gagal menghapus loket"
              })
            }
            );
          break;

        // ======================================

        case doSyntax.cancel:
          handleGetAllCounter()
          setUpdateData()
          setCreateData()
          break;
        default:
          break;
      }
    }
  }

  const handleShowModal = async () => {
    setModal(true);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const renderPopUpConfrimation = () => {
    return (
      <PopupConfirmation
        show={popupDelete || popupUpdate || popupCreate || popupCancel}
        message={
          popupDelete ? (
            "Anda yakin ingin menghapus layar tunggu ini?"
          ) : popupUpdate ? (
            "Simpan perubahan layar tunggu ini?"
          ) : popupCreate ? (
            "Simpan perubahan layar tunggu ini?"
          ) : popupCancel ? (
            <>
              Batalkan perubahan informasi layar loket ini? <br /> (Perubahan
              tidak akan disimpan)
            </>
          ) : (
            ""
          )
        }
        handleClose={() => {
          if (!loading) {
            setPopupDelete(false);
            setPopupCreate(false);
            setPopupCancel(false);
            setPopupUpdate(false);
          }
        }}
        handleSave={() => {
          if (!loading) {
            if (popupDelete) handleFunction(doSyntax.delete);
            if (popupUpdate) handleFunction(doSyntax.update);
            if (popupCreate) handleFunction(doSyntax.create);
            if (popupCancel) handleFunction(doSyntax.cancel);

            setPopupDelete(false);
            setPopupCreate(false);
            setPopupCancel(false);
            setPopupUpdate(false);
          }
        }}
      />
    );
  };

  const renderCounterList = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            paddingLeft: "10px"
          }}
        >
          {/* Show screen list if there is at least 1 screen */}
          {Array.isArray(counter) && counter?.filter((data) => {
            if (!loket) {
              return data.sequence > 0
            } else {
              return data.sequence == 0
            }
          }).map((data) => (
            <div
              className={`config-antrol-screen ${data?._id === updateData?._id
                ? "config-antrol-screen-active"
                : "config-antrol-screen-inactive"
                }`}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div className="overflow-ellipsis">
                <span className="fw-bold fs-16">{loket ? 'Loket' : ''} {data.name}</span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  gap: "60px",
                  width: "50%",
                }}
              >
                {!loket ?
                  <>
                    <span className="fw-semibold fs-10"> {data.sequence}</span>
                    <button
                      style={{ border: "none", backgroundColor: "transparent" }}
                      onClick={() => {
                        if (!loading) {
                          setUpdateData({
                            name: data?.name,
                            sequence: data?.sequence,
                            category: data?.category,
                            _id: data?._id,
                          })
                        }
                      }
                      }
                    >
                      <Image src="/img/icon/icon-edit.svg" height={"25px"} />
                    </button>
                  </> : null
                }
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  const renderCounterDetails = () => {
    return (
      <>
        <div className="header-content" style={{ paddingRight: "10px" }}>
          <span className="fw-bold fs-20">Detail Layar Loket</span>
          <Button
            title="Hapus"
            className="button-delete mb-2"
            onClick={() => {
              if (!loading) {
                setPopupDelete(true)
              }
            }}
          />
        </div>
        <div
          className="config-antrolscreen-container"
          style={{ padding: "10px" }}
        >
          <Col className="mb-3">
            <span className="fw-semi-bold">Nama Loket</span>
            <Input
              type="text"
              placeholder={"Nama Loket"}
              onChange={(e) =>
                setUpdateData({
                  ...updateData,
                  name: e.target.value,
                })
              }
              value={updateData.name}
            />
          </Col>
          <Col className="mb-3">
            <span className="fw-semi-bold">
              Urutan loket saat tampil di layar tv
            </span>
            <InputSelect
              className={"input-select-control"}
              isClearable={true}
              placeholder={"Pilih Urutan"}
              defaultValue={{
                value: updateData.sequence,
                label: updateData.sequence,
              }}
              options={sequenceOptions}
              onChange={(e) => {
                setUpdateData({
                  ...updateData,
                  sequence: e.value,
                });
              }}
            />
          </Col>
          <Col className="d-flex justify-content-end">
            <Button
              title="Batal"
              className="button-cancel"
              onClick={() => {
                setPopupCancel(true);
              }}
            />
            <Button
              title="Simpan"
              className="button-save"
              onClick={() => {
                if (!loading) {
                  handleFunction(doSyntax.update)
                }
              }}
            />
          </Col>
        </div>
      </>
    );
  };

  // modal tambah layar tunggu
  const renderModal = () => {
    return (
      <Modal
        size="lg"
        show={modal}
        handleClose={() => {
          setModal(false);
          handleFunction(doSyntax.cancel)
        }}
        title="Tambah Layar Loket"
        className={"font-home-display"}
      >
        {!loket ? <>
          <Row className="m-0">
            <Col className="mb-3">
              <span className="fw-semi-bold title-input">Nama Loket</span>
              <Input
                type="text"
                placeholder={"Nama Loket"}
                onChange={(e) =>
                  setCreateData({
                    ...createData,
                    name: e.target.value,
                  })
                }
                value={createData?.name}
              />
            </Col>
          </Row>
          <Row className="m-0">
            <Col className="mb-3">
              <span className="fw-semi-bold title-input">Urutan</span>
              <InputSelect
                className={"input-select-control"}
                placeholder={"Pilih Urutan Loket"}
                options={sequenceOptionsCreate}
                onChange={(e) => {
                  setCreateData({
                    ...createData,
                    sequence: e.value,
                  });
                }}
              />
            </Col>
          </Row>
        </>
          :
          null
        }

        <Row className="m-0 mb-2 mt-4">
          <Col>
            <Button
              title="Simpan"
              onClick={() => {
                if (!loading) {
                  handleFunction(doSyntax.create)
                }
              }}
            />
          </Col>
        </Row>

        <Row className="m-0">
          <Col>
            <Button
              title="Batal"
              onClick={() => {
                if (!loading) {
                  handleFunction(doSyntax.cancel)
                  setModal(false);
                }
              }}
              className="button-cancel-modal"
            />
          </Col>
        </Row>
      </Modal>
    );
  };

  return (
    <div style={{ overflow: 'hidden' }} className="font-home-display">
      <HeaderDashboard fontSize={"fs-4"} />
      <div style={{height:'75vh'}} className="large-container">
        <div style={{ height: '90%'}} className="large-inner-container">
          <Row style={{ overflow: 'hidden', width:'100%', height: '100%'}}>
            <div style={{ width: '100%', display: 'flex'}} className="overflow-medium">
            <Col xs={3} style={{ position: "relative", height: "750px" }} className="locket-list-col">
            <div onClick={handleBack} className="d-flex mb-4">
                <HiArrowSmLeft className="fs-30 cl-green cursor-pointer" />
                <div className="fw-bold fs-18 cursor-pointer mt-01 ml-04">Kembali</div>
            </div>
            <div className="partition"></div>
              <div className="header-content">
                <span className="fw-bold fs-20">List Loket</span>
               <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    gap: "30px",
                    width: "50%",
                  }}
                >
                 {!loket ?  
                 <>
                 <span className="fw-bold fs-10">Urutan Loket</span> 
                  <button className="button-create">
                    <Image
                      src="/img/icon/icon-create-button.svg"
                      height={"40px"}
                      onClick={() => {
                        if (!loading) {
                        handleShowModal()
                        }
                      }}
                    />
                  </button>
                 </>
                  : null}
                </div> 
              </div>
              <div style={{overflow: "hidden", height: '100%', position: 'relative'}}>
              <div className="config-antrol-screen-list">
                {renderCounterList()}
              </div>
              </div>
            </Col>
            <Col xs={9} style={{ paddingLeft: "25px", paddingTop: "50px" }} className="locket-detail-col">
              {/* Show screen details if there is at least 1 screen */}
              {updateData?._id && !loket ? renderCounterDetails() : null}
            </Col>
            </div>
          </Row>
        </div>
        <div className="text-center mt-2">
          <span>© Copyright AIDO Health. All Rights Reserved.</span>
        </div>
      </div>
      {renderModal()}
      {renderPopUpConfrimation()}
    </div>
  )
}