import axiosBookAppointment from "../helpers/axiosBookAppointment";
import { GeneralTimeoutRealtime } from "../varConstant";
import { masterDataConstants, policlinicConstants, ERROR_RESPONSE } from "./constants";

const hospitalId = () => localStorage.getItem("hospitalId");

let timer = {
	getSpecializations: null,
	getDoctorsHospitals: null
}

export function getPoliBpjs() {
    return async (dispatch) => {
        try {
            dispatch({ type: masterDataConstants.GET_POLI_REQUEST });
            const { data } = await axiosBookAppointment.get(
                `/poli/bpjs?hospital_id=${hospitalId()}`
            );
            if(data?.data){
                dispatch({
                    type: masterDataConstants.GET_POLI_SUCCESS,
                    payload: data || [],
                });
                return data?.data || [];
            }
            return data?.data || []
        } catch (error) {
            dispatch({
                type: masterDataConstants.GET_POLI_FAILURE,
                payload: error?.response?.data || error,
            });
            return error?.response?.data || error
        }
    }
}

export function getAllPoli() {
    return async (dispatch) => {
        try {
            dispatch({ type: masterDataConstants.GET_POLI_REQUEST });
            const { data } = await axiosBookAppointment.get(`/poli/option/${hospitalId()}`);
            if(data?.data?.poli){
                dispatch({
                    type: masterDataConstants.GET_POLI_SUCCESS,
                    payload: {
                        data : data?.data?.poli || []
                    },
                });
                return data?.data?.poli || [];
            }
            return data?.data?.poli || []
        } catch (error) {
            return error?.response?.data || error
        }
    }
}

export function getDoctorsHospitals(hospitalId, schedule_date) {
    return async (dispatch) => {
        try {
            clearTimeout(timer.getSpecializations)
            timer.getSpecializations = setTimeout(async function () {
                dispatch({ type: masterDataConstants.GET_DOCTOR_HOSPITAL_REQUEST });
                const res = await axiosBookAppointment.get(
                    `/doctors-hospitals/list/schedule/${hospitalId}?schedule_date=${schedule_date}`,
                );
                if (res.status === 200) {
                    dispatch({
                        type: masterDataConstants.GET_DOCTOR_HOSPITAL_SUCCESS,
                        payload: res.data,
                    });
                    return res.data;
                }
            }, GeneralTimeoutRealtime)
        } catch (error) {
            dispatch({
                type: masterDataConstants.GET_DOCTOR_HOSPITAL_FAILURE,
                payload: { error: error?.response?.data?.error },
            });

        }
    };
};

export function getSchedules (form) {
	return async (dispatch) => {
		dispatch({ type: masterDataConstants.GET_SCHEDULE_REQUEST });
		const res = await axiosBookAppointment.post(
			"/doctor-schedules/schedule/slot/v2",
			form,
		);
		if (res.status === 200) {
			dispatch({
				type: masterDataConstants.GET_SCHEDULE_SUCCESS,
				payload: res.data,
			});
			return res.data;
		} else {
			dispatch({
				type: masterDataConstants.GET_SCHEDULE_FAILURE,
				payload: { error: res.data.error },
			});
		}
	};
};

export function clearDoctorHospital  () {
    return async (dispatch) => {
        dispatch({ type: masterDataConstants.GET_DOCTOR_HOSPITAL_CLEAR });
    }
}

export function clearSchedule  () {
    return async (dispatch) => {
        dispatch({ type: masterDataConstants.GET_SCHEDULE_CLEAR });
    }
}

export const getAllDoctorPoli = () => {
    return async (dispatch) => {
      dispatch({ type: policlinicConstants.GET_ALL_DOCTOR_POLI_LIST_REQUEST });
  
      const res = await axiosBookAppointment.get(
        `/doctor-schedules/fktp/queue/get-doctor-poli?hospitalId=${hospitalId()}`
      ).catch((err) => {
        return dispatch({
          type: policlinicConstants.GET_ALL_DOCTOR_POLI_LIST_FAILURE,
          payload: err.response?.data || ERROR_RESPONSE
        });
      });
  
      if(res.status === 200) {
        dispatch({
          type: policlinicConstants.GET_ALL_DOCTOR_POLI_LIST_SUCCESS,
          payload: res.data.data || [],
        });
  
        return res.data.data || [];
      }
    }
  } 

export const getPoliScreenSchedule = (data) => {
    return async (dispatch) => {
        dispatch({ type: policlinicConstants.GET_POLICLINIC_SCREEN_DOCTOR_SCHEDULE_REQUEST });

        const res = await axiosBookAppointment.post(
            `/doctors-hospitals/fktp/queue/poli-schedule/get`,
            data
        ).catch((err) => {
            return dispatch({
                type: policlinicConstants.GET_POLICLINIC_SCREEN_DOCTOR_SCHEDULE_FAILURE,
                payload: err.response?.data || ERROR_RESPONSE
            });
        });

        if (res.status === 200) {
            dispatch({
                type: policlinicConstants.GET_POLICLINIC_SCREEN_DOCTOR_SCHEDULE_SUCCESS,
                payload: res.data || [],
            });

            return res.data || [];
        }
    }
}