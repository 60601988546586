import { Col, Row } from "react-bootstrap";
import Topbar from "../../components/Topbar/index.";
import React, { useEffect, useState } from 'react';
import Button from "../../components/Button";
import BackButton from "../../components/BackButton";
import { useNavigate, useParams } from "react-router-dom";
import "./index.css";
import Pagination from "../../components/Pagination";
import { getAllPoli, getPoliBpjs, getDoctorsHospitals } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const itemsPerPage = 12;

const PatientDisplayPoliclinic = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { machineId } = useParams()

    //selector
    const doctorsHospitals = useSelector((state) => state.bookAppointment.dataDoctorsHospitals)

    const [paginationProps, setPaginationProps] = useState({
        page: 1,
        limit: itemsPerPage,
        totalData: doctorsHospitals?.length,
    });

    const handlePageChange = (page, limit) => {
        setPaginationProps((prev) => ({ ...prev, page, limit }));
    };

    const currentClinics = () => {

        const filterDoctorLeave = doctorsHospitals ? doctorsHospitals.filter((el) => el.leave === false) : []
        const scheduleDayPoli = []

        let dayNow = moment().day()
        if (dayNow === 0) dayNow = 7 //sunday
        if (filterDoctorLeave.length > 0) {
            filterDoctorLeave.forEach((el) => {
                if (el?.scheduleList?.tm_doctor_schedules_v2s?.length) {
                    el?.scheduleList?.tm_doctor_schedules_v2s?.forEach((sc) => {
                        sc?.tm_doctor_schedule_items?.forEach((si) => {
                            if (si?.active === '1' && si?.day === dayNow) {
                                return scheduleDayPoli.push(el?.scheduleList?.tm_poli)
                            }
                        })
                    })
                }
            })
        }

        const uniqueData = scheduleDayPoli ? scheduleDayPoli.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.id === value.id
            ))
        ) : [];

        return uniqueData ? uniqueData.slice(
            (paginationProps.page - 1) * paginationProps.limit,
            paginationProps.page * paginationProps.limit
        ) : []
    };


    const handleBack = () => {
        navigate(-1);
    }

    const handleNextPage = (poliId) => {
        navigate(`/patient-display/policlinic/${machineId}/${poliId}`)
    }

    useEffect(() => {

        const schedule_date = moment().format("YYYY-MM-DD");
        const hospitalId = localStorage.getItem("hospitalId")
        dispatch(getDoctorsHospitals(hospitalId, schedule_date))

    }, [dispatch])

    useEffect(() => {
        setPaginationProps((prev) => ({ ...prev, totalData: currentClinics().length }));
    }, [doctorsHospitals])

    return (
        <>
            <div className="patient-bg font-family-patient-display" style={{ justifyContent: 'space-between' }}>
                <div className="patient-header">
                    <Topbar />
                </div>

                <Row className="m-0 mb-4 d-flex justify-content-center" >
                    <Col md={11}>
                        <Row className="justify-content-center title-hospital-service">
                            <h2>Silakan pilih poliklinik spesialis yang ingin Anda tuju</h2>
                        </Row>
                    </Col>
                </Row>

                <div className="patient-container" style={{ flex: 'none' }}>

                    <div className="clinic-selection-screen">

                        <div className="clinic-grid">
                            {currentClinics().map((clinic) => (
                                <Button
                                    key={clinic.id}
                                    title={clinic.name}
                                    onClick={() => handleNextPage(clinic.id)}
                                    className="clinic-button"
                                />
                            ))}
                        </div>
                    </div>
                </div>

                <div className="pagination-controls">
                    <Col sm={3} className="text-center">
                        <BackButton handleBack={handleBack} />
                    </Col>
                    {
                        paginationProps?.totalData && <Pagination
                            style={{ marginTop: '24px', marginBottom: '8px' }}
                            currData={currentClinics}
                            props={paginationProps}
                            setProps={setPaginationProps}
                            onChange={handlePageChange}
                        />
                    }
                </div>
            </div>
        </>
    );
}

export default PatientDisplayPoliclinic;