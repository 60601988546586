import { useState } from "react";
import Form from "react-bootstrap/Form";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import "./index.css";

const Input = (props) => {
  const [isPasswordShow, setIsPasswordShow] = useState(false);

  return (
    <>
      <Form.Control
        className="input-control"
        type={
          props.password ? (isPasswordShow ? "text" : "password") : props.type
        }
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
      />
      {props.password && (
        <div className="d-flex justify-content-end input-div-password">
          {isPasswordShow ? (
            <BsEye
              className="input-password"
              onClick={() => setIsPasswordShow(false)}
            />
          ) : (
            <BsEyeSlash
              className="input-password"
              onClick={() => setIsPasswordShow(true)}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Input;
